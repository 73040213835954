import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  CardMedia,
  Avatar,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import RedditIcon from "@mui/icons-material/Reddit";
import NavBar from "../global/header";
import Grid from "@mui/material/Grid";
import Footer from "../global/footer";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import tv from "../main/img/tv.gif";
import CardContent from "@mui/material/CardContent";
import seoImg from "../main/img/seo.jpg"; // Add this image
import DigitalImg from "../main/img/Digital.png";
import EmailMarketingImg from "../main/img/email-marketing.jpg";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  MetadescriptionSetterForPage,
  TitleSetterForPage,
} from "../utils/MetaDescriptionSetter";
import { Helmet } from "react-helmet-async";
import Meta from "../Components/Meta";

function Banner1(article) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userArticle = article.article;

  return (
    <>
      <MetadescriptionSetterForPage metadescription={article.metadescription} />
      <TitleSetterForPage title={"SEO"} />
      {/* 
      <Helmet>
        <meta
          name="description"
          content={article.metadescription || "Default SEO description"}
        />
        <title>{article.headertext || "Marketing Inc"}</title>
      </Helmet> */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          // border: "2px solid black",
          padding: isMobile ? 3 : 0,
        }}
      >
        <Grid item xs={12} md={11} lg={12}>
          <Grid item xs={12} md={11} lg={11}>
            <div
              style={{ textAlign: "left", overflowX: "hidden" }}
              dangerouslySetInnerHTML={{ __html: userArticle.body }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function NewsletterSignUp() {
  return (
    <>
      <MetadescriptionSetterForPage metadescription={"SEO"} />
      <TitleSetterForPage title={"SEO"} />

      <Grid sx={{ textAlign: "left", mt: 3 }}>
        <Grid
          container
          xs={12}
          md={11}
          lg={11}
          spacing={1}
          justifyContent="left"
        >
          <Grid item xs={12} md={10} lg={10}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Sign up for the Marketing Inc Daily Newsletter
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
              Get the latest news on media, marketing and the future of TV,
              delivered each weekday morning
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={8}>
            <TextField
              fullWidth
              label="Business email"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderBottom: "1px solid black",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={8}>
            <TextField
              fullWidth
              label="Job title"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderBottom: "1px solid black",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              sx={{
                color: "primary",
                backgroundColor: "#000000",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#FFFFFF", // Changes button background to white
                  color: "#000000", // Changes text color to black
                },

                mt: { xs: 6, sm: 0 },
                mb: { xs: 5, sm: 0 },

                px: 3,
                py: 1,
                textTransform: "none",
              }}
            >
              SIGN UP
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function ImageSection3() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen
  const [article, setArticle] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        const articlesArray = response.data.slice(0, 3);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <>
      <MetadescriptionSetterForPage metadescription={"SEO"} />
      <TitleSetterForPage title={"SEO"} />

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="flex-start"
        paddingTop={2}
      >
        {article.map((article, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            key={index}
            sx={{ position: "relative", cursor: "pointer" }}
            onClick={() => handleClick(article.slug)}
          >
            <Grid
              sm={10}
              sx={{
                display: "flex",
                flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
                justifyContent: "space-around",
                height: isMobile ? "auto" : 400, // Fixed height for desktop view
                width: isMobile ? "auto" : "auto",
                boxShadow: isMobile ? 0 : 0,
                padding: isMobile ? 0 : 1, // Adjust padding for mobile
                transition: isMobile ? "none" : "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.03)",
                },
              }}
            >
              <CardMedia
                component="img"
                image={article.imagelink}
                // alt={article.headertext}

                sx={{
                  height: isMobile ? 100 : "400px", // Fixed height for images
                  width: isMobile ? "40%" : "50%", // On mobile, make the image 40% of the card width

                  // height: isMobile ? 100 : 200, // Fixed height for images
                  // width: isMobile ? "40%" : "100%", // On mobile, make the image 40% of the card width

                  objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
                  borderRadius: "2px",
                }}
              />
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%", // Ensure it takes full width of the card
                  padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
                  boxSizing: "border-box",
                  height: isMobile ? "auto" : "100%", // Adjust height for desktop view
                }}
              >
                <Typography
                  variant="body2"
                  display="block"
                  gutterBottom
                  sx={{
                    color: "red",
                    fontSize: isMobile ? "0.74rem" : "0.74rem", // Responsive font size
                    textAlign: "left",
                    // marginBottom: isMobile ? "8px" : "14px",
                    height: "auto",
                    fontWeight: "400",
                  }}
                >
                  {article.category}
                </Typography>

                <Typography
                  variant="body2"
                  display="block"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontSize: isMobile ? "1rem" : "1.2rem", // Responsive font size
                    textAlign: "left",
                    marginBottom: isMobile ? "8px" : "14px",
                    height: "auto",
                    fontWeight: "700",
                  }}
                >
                  {article.headertext}
                </Typography>

                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: isMobile ? 1 : 2,
                  }}
                >
                  {!isMobile && ( // Only show eye icon in desktop view
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                        color: "#555",
                      }}
                    >
                      <RemoveRedEyeIcon
                        sx={{
                          marginRight: 0.5,
                          color: "grey.700",
                          opacity: 0.7,
                        }}
                      />
                      {article.clicks}
                    </Typography>
                  )}
                  {!isMobile && ( // Only show Read more link in desktop view
                    <Typography
                      component="a"
                      href="#"
                      variant="body2"
                      sx={{
                        textDecoration: "none",
                        color: "primary.main",
                        "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      Read more »
                    </Typography>
                  )}
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export const SEO = () => {
  const [article, setArticle] = useState("");
  const navigate = useNavigate();

  const { slug } = useParams();
  console.log(slug);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    const sendDataToAddArticleForm = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/cards/getonearticles/${slug}`
        );
        const article = response.data;
        console.log(article);

        setArticle(article);

        // call the cliks api with id
        await axios.post(
          `${process.env.REACT_APP_URL}/api/cards/clicked/${article._id}`
        );
      } catch (error) {
        console.error("Error sending data to addarticles:", error);
      }
    };

    // Call the function to send data
    sendDataToAddArticleForm();
  }, [slug]);
  return (
    <>
      <MetadescriptionSetterForPage metadescription={article.metadescription} />
      <TitleSetterForPage title={article.headertext} />

      <Grid
        xs={12}
        md={12}
        lg={12}
        container
        // marginLeft={2}
        // border={"2px solid black"}
        sx={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <NavBar />

        {/* <Grid container xs={12} md={9} lg={9} > */}
        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          // border='2px solid black'
          mt={isMobile ? 10 : 15}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: 2,
            py: 4,
            textAlign: "center",
            "@media (max-width: 600px)": {
              px: 1,
              py: 2,
            },
          }}
        >
          <Typography
            variant="caption"
            color="error"
            sx={{ fontWeight: "bold" }}
          >
            MEMBER EXCLUSIVE
          </Typography>
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
          >
            {article.headertext}
          </Typography>

          <Grid
            item
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 3 }}
            spacing={1}
            paddingBottom={2}
          >
            {/* <Grid item>
              <Typography variant="subtitle2" component="p">
                By{" "}
              </Typography>
              <Link
                to={`/profile/${article.author}`}
                underline="hover"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                  },
                }}
              >
                {article.author}
              </Link>
              <Typography>
                {" "}
                {new Date(article.date).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}{" "}
              </Typography>
            </Grid> */}

            <Grid item>
              <Typography
                variant="subtitle2"
                component="p"
                sx={{
                  display: "inline-block",
                  color: "gray",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  mr: 1,
                }}
              >
                By
              </Typography>

              <Typography
                onClick={() => navigate(`/profile/${article.author}`)} // Navigate to the author's profile
                sx={{
                  fontSize: "1rem", // Size of the author name
                  fontWeight: "bold", // Bold the name
                  // color: "Fuchsia", // Black color to match the design
                  color: "Navy", // Black color to match the design

                  cursor: "pointer", // Pointer to indicate it's clickable
                  "&:hover": {
                    textDecoration: "none", // Remove hover effect
                  },
                  transition: "color 0.3s ease", // Smooth transition for hover
                }}
              >
                {article.author}
              </Typography>

              <Typography
                variant="body2"
                component="span"
                sx={{
                  display: "inline-block",
                  color: "gray",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  ml: 1,
                }}
              >
                {new Date(article.date).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton href="#" sx={{ color: "grey", p: 0.5 }}>
                <FacebookIcon fontSize="small" />
              </IconButton>
              <IconButton href="#" sx={{ color: "grey", p: 0.5 }}>
                <TwitterIcon fontSize="small" />
              </IconButton>
              <IconButton href="#" sx={{ color: "grey", p: 0.5 }}>
                <LinkedInIcon fontSize="small" />
              </IconButton>
              <IconButton href="#" sx={{ color: "grey", p: 0.5 }}>
                <RedditIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12} md={10} lg={10}></Grid>

          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            component="img"
            p={isMobile ? 3 : 0}
            rel="preload"
            src={article.imagelink}
            alt="Sample GIF"
            sx={{
              height: "auto",
              mb: 0,
              width: "auto",
            }}
          />
        </Grid>
        {/* </Grid> */}

        <Grid item xs={12} md={10} lg={10} p={isMobile ? 3 : 0}>
          <Divider
            sx={{
              orientation: "horizontal",
              borderColor: "#000000",
              flex: 1,
              // fontWeight: "bold",
              // marginLeft: "55px",
              mb: 2,
              justifyContent: "space-between",
              // mb: { xs: 0, md: 0 },
            }}
          />
        </Grid>

        <Grid
          container
          // spacing={7}
          // gap={1}
          // mt={-6}
          xs={12}
          md={10}
          lg={9}
          sx={{ backgroundColor: "" }}
        >
          <Grid item xs={12} md={12} lg={12} p={isMobile ? 3 : 0}>
            <Banner1 article={article} />

            <Grid
              item
              xs={12}
              md={11}
              lg={11}
              p={isMobile ? 3 : 0}
              paddingTop={3}
            >
              <Divider
                sx={{
                  orientation: "horizontal",
                  borderColor: "#000000",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              />
            </Grid>

            <NewsletterSignUp />
          </Grid>
        </Grid>

        <Grid container xs={12} md={10} lg={10} p={isMobile ? 3 : 0} mt={5}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default SEO;
