import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import { Grid, Card, CardContent, Typography, IconButton } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import CategoryIcon from "@mui/icons-material/Category";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import SyncLoader from "react-spinners/SyncLoader"; // Import SyncLoader

const Dashboard = () => {
  const [articleCount, setArticleCount] = useState(0);
  const [articleCountLoader, setArticleCountLoader] = useState(true);

  const [authorCount, setAuthorCount] = useState(0);
  const [authorCountLoader, setAuthorCountLoader] = useState(true);

  const [categoriesCount, setCategoriesCount] = useState(0);
  const [categoriesCountLoader, setCategoriesCountLoader] = useState(true);

  const [subscriberCount, setSubscriberCount] = useState(0);
  const [subscriberCountLoader, setSubscriberCountLoader] = useState(true);

  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer open/close

  const navigate = useNavigate(); // Initialize navigate function

  // Fetch article count from backend
  useEffect(() => {
    const fetchArticleCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/api/cards/getarticlescount`
        );
        const data = await response.json();
        setArticleCount(data.count);
        setArticleCountLoader(false);
      } catch (error) {
        console.error("Error fetching article count:", error);
      }
    };

    fetchArticleCount();
  }, []);

  // Fetch author count from backend
  useEffect(() => {
    const fetchAuthorCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/api/getauthorcount`
        );
        const data = await response.json();
        setAuthorCount(data.count);
        setAuthorCountLoader(false);
      } catch (error) {
        console.error("Error fetching author count:", error);
      }
    };
    fetchAuthorCount();
  }, []);

  // Fetch categories count from backend
  useEffect(() => {
    const fetchCategoriesCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/api/getcategoriescount`
        );
        const data = await response.json();
        setCategoriesCount(data.count);
        setCategoriesCountLoader(false);
      } catch (error) {
        console.error("Error fetching categories count:", error);
      }
    };
    fetchCategoriesCount();
  }, []);

  // Fetch subscriber count from backend
  useEffect(() => {
    const fetchSubscriberCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/api/getsubscribercount`
        );
        const data = await response.json();
        setSubscriberCount(data.count);
        setSubscriberCountLoader(false);
      } catch (error) {
        console.error("Error fetching subscriber count:", error);
      }
    };
    fetchSubscriberCount();
  }, []);

  const data = [
    {
      title: "Total Articles",
      count: articleCount,
      icon: <ArticleIcon fontSize="large" />,
      link: "/articlestable",
      color: "#2196F3",
      loader: articleCountLoader,
    },
    {
      title: "Total Authors",
      count: authorCount,
      icon: <PersonIcon fontSize="large" />,
      link: "/addauthor",
      color: "#4CAF50",
      loader: authorCountLoader,
    },
    {
      title: "Total Categories",
      count: categoriesCount,
      icon: <CategoryIcon fontSize="large" />,
      link: "/addcategories",
      color: "#FFC107",
      loader: categoriesCountLoader,
    },
    {
      title: "Total Subscriptions",
      count: subscriberCount,
      icon: <SubscriptionsIcon fontSize="large" />,
      link: "/subscribe",
      color: "#F44336",
      loader: subscriberCountLoader,
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <DrawerHeader drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          // backgroundColor: "#E0F7FA", // Light teal background color
          transition: "margin 0.3s",
          marginLeft: drawerOpen ? "240px" : "60px", // Adjust the margin based on drawer state
        }}
      >
        <Grid container direction="row" mt={9} justifyContent="center">
          <Typography
            variant="h4"
            sx={{
              mb: 4,
              fontWeight: "bold",
              textAlign: "center",
              color: "#004D40", // Dark teal color for heading
            }}
          >
            Dashboard
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            {data.map((item) => (
              <Grid item xs={12} sm={6} md={5} key={item.title}>
                <Card
                  sx={{
                    backgroundColor: "#33A8B8", // Teal color for card background
                    borderRadius: 4,
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15), 0 0 10px rgba(255, 255, 255, 0.5)",


                    padding: 2,
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: 6,
                      backgroundColor: "#375E97", // Darker teal on hover
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(item.link)}
                >
                  <CardContent sx={{ textAlign: "center", color: "#FFFFFF" }}>
                    {" "}
                    {/* White text color */}
                    <IconButton
                      sx={{
                        backgroundColor: item.color,
                        color: "#fff",
                        mb: 2,
                        "&:hover": { backgroundColor: item.color },
                        fontSize: 40,
                        padding: 2,
                      }}
                    >
                      {item.icon}
                    </IconButton>
                    <Typography variant="h6" color="inherit" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography
                      variant="h4"
                      color="inherit"
                      sx={{ fontWeight: "bold" }}
                    >
                      {item.loader ? (
                        <SyncLoader color="#B2EBF2" size={15} margin={5} /> // Light teal for loader
                      ) : (
                        item.count
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
