import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Snackbar,
  Checkbox,
} from "@mui/material";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SyncLoader from "react-spinners/SyncLoader";

export const AddAuthor = () => {
  const [authorName, setAuthorName] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  const [description, setDescription] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [file, setFile] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // 'success' or 'error'
  const [editingAuthor, setEditingAuthor] = useState(null); // Track which author is being edited
  const [imagePreview, setImagePreview] = useState(""); // Image preview URL
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [selectAll, setSelectAll] = useState(false); // For select all checkbox
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/link`)

      .then((response) => {
        // console.log("Fetched authors data:", response.data);
        const formattedAuthors = response.data.authors
          .reverse()
          .map((author, index) => ({
            id: author.id || index + 1,
            _id: author?._id,
            name: author.authorName || "N/A",
            linkedIn: author.linkedIn || "N/A",
            twitter: author.twitter || "N/A",
            description: author.description || "N/A",
            file: author.file
              ? `${author.file}`
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&s",
          }));
        setAuthors(formattedAuthors);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the authors!", error);
        setLoading(false);
      });
  }, []);

  const handleClickOpen = (author = null) => {
    setEditingAuthor(author); // Set the author to be edited
    setOpen(true);
    setShowForm(true);

    if (author) {
      setAuthorName(author.name || "");
      setLinkedIn(author.linkedIn || "");
      setTwitter(author.twitter || "");
      setDescription(author.description || "");
      setFile(null);
      setImagePreview(author.file || ""); // Set image preview
    } else {
      setAuthorName("");
      setLinkedIn("");
      setTwitter("");
      setDescription("");
      setFile(null);
      setImagePreview(""); // Clear image preview
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowForm(false);
    setEditingAuthor(null); // Clear editing author
    setImagePreview(""); // Clear image preview
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0])); // Update preview URL
  };

  const handleCancelImage = () => {
    setFile(null);
    setImagePreview(""); // Clear image preview
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("authorName", authorName);
    formData.append("linkedIn", linkedIn);
    formData.append("twitter", twitter);
    formData.append("description", description);

    if (file) {
      formData.append("file", file);
    }

    try {
      const url = editingAuthor
        ? `${process.env.REACT_APP_URL}/api/updateauthor/${editingAuthor._id}`
        : `${process.env.REACT_APP_URL}/api/addauthor`;

      const method = editingAuthor ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setResponseMessage(data.message);
        handleClose();

        if (editingAuthor) {
          setAuthors((prevAuthors) =>
            prevAuthors.map((author) =>
              author._id === editingAuthor._id
                ? { ...author, ...data.author }
                : author
            )
          );
        } else {
          setAuthors((prevAuthors) => [data.author, ...prevAuthors]);
        }

        setSnackbarOpen(true); // Show Snackbar on success
      } else {
        setResponseMessage(data.message || "Error creating or updating author");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setResponseMessage("Error: Unable to create or update author");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDelete = async (_id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/api/deleteauthor/${_id}`
      );

      if (response.status === 200) {
        setResponseMessage("Author deleted successfully");
        setAuthors(authors.filter((author) => author._id !== _id));
        setSnackbarOpen(true); // Show Snackbar on success
      } else {
        setResponseMessage("Error deleting author");
        setSnackbarSeverity("error");
        setSnackbarOpen(true); // Show Snackbar on error
      }
    } catch (error) {
      setResponseMessage("Error: Unable to delete author");
      setSnackbarSeverity("error");
      setSnackbarOpen(true); // Show Snackbar on error
    }
  };

  // Function to handle delete button click
  const handleDeleteClick = (_id) => {
    setArticleToDelete(_id);
    setOpenConfirmDialog(true);
  };

  // Function to handle confirm delete
  const handleConfirmDelete = () => {
    if (articleToDelete) {
      handleDelete(articleToDelete);
    }
    setOpenConfirmDialog(false);
    setArticleToDelete(null);
  };

  // Function to handle cancel delete
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setArticleToDelete(null);
  };

  // const handleSelectAll = () => {
  //   if (selectAll) {
  //     setSelectedAuthors([]); // Deselect all authors
  //   } else {
  //     setSelectedAuthors(authors.map((author) => author._id)); // Select all authors
  //   }
  //   setSelectAll(!selectAll); // Toggle selectAll state
  // };

  // Handle individual checkbox change
  // const handleAuthorSelect = (_id) => {
  //   setSelectedAuthors((prevSelected) =>
  //     prevSelected.includes(_id)
  //       ? prevSelected.filter((id) => id !== _id) // Deselect author
  //       : [...prevSelected, _id] // Select author
  //   );
  // };

  // const handleDeleteSelected = async () => {
  //   try {
  //     await Promise.all(
  //       selectedAuthors.map((_id) =>
  //         axios.delete(`${process.env.REACT_APP_URL}/api/deleteauthor/${_id}`)
  //       )
  //     );

  //     setAuthors(authors.filter((author) => !selectedAuthors.includes(author._id)));
  //     setSelectedAuthors([]); // Clear selected authors
  //     setResponseMessage("Selected authors deleted successfully");
  //     setSnackbarOpen(true); // Show Snackbar on success
  //   } catch (error) {
  //     setResponseMessage("Error: Unable to delete selected authors");
  //     setSnackbarOpen(true); // Show Snackbar on error
  //   }
  // };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  return (
    <Box p={6} display="flex" justifyContent={"center"} direction="row" mt={12}>
      <DrawerHeader />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Auto hide after 6 seconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position of Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use dynamic severity
          sx={{
            width: "100%",
            backgroundColor: snackbarSeverity === "success" ? "#90EE90" : "red", // Green for success, red for error
            color: "white", // Ensure text is readable
          }}
        >
          {responseMessage}
        </Alert>
      </Snackbar>

      <Grid
        container
        xs={12}
        md={10}
        lg={10}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Grid
            sx={{
              p: 3,
              background: darkMode
                ? "linear-gradient(to left, #93E9BE, #BBDEFB, #F5F5DC, #64B5F6)"
                : "linear-gradient(to right, #93E9BE, #B0C4DE, #BBDEFB, #BBDEFB)",
              // borderRadius: 2,
              position: "relative",
              boxShadow: 8,
            }}
          >
            {/* <Grid item xs={12} sx={{ p: 2, display: "flex", justifyContent: "flex-start" }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteSelected}
              disabled={selectedAuthors.length === 0} // Disable if no authors are selected
            >
              Delete Selected
            </Button>
          </Grid> */}

            <Typography
              variant="h5"
              component="div"
              sx={{ mb: 2, color: "Midnight blue" }}
            >
              Author
            </Typography>
            <Button
              variant="contained"
              // color="primary"
              onClick={() => handleClickOpen()}
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                padding: "6px 16px",
                fontSize: "14px",
                borderRadius: "8px",
                textTransform: "none",
                minWidth: "100px",
                // border: "2px solid black",
              }}
            >
              Add Author
            </Button>

            {/* Table */}
            <Grid
              sx={{
                height: "auto",
                width: "100%",
                backgroundColor: "white",
                // borderRadius: 2,
                // p: 2,
              }}
            >
              {loading ? ( // Show loader if loading is true
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <SyncLoader color="#4A90E2" />
                </Box>
              ) : (
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    boxShadow:
                      "0px 8px 16px rgba(0, 0, 0, 0.2), 0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <thead>
                    <tr>
                      {/* <th>
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    inputProps={{ "aria-label": "select all authors" }}
                  />
                </th> */}
                      <th style={tableHeaderStyle}>ID</th>
                      <th style={tableHeaderStyle}>Author Name</th>
                      <th style={tableHeaderStyle}>LinkedIn</th>
                      <th style={tableHeaderStyle}>Twitter</th>
                      <th style={tableHeaderStyle}>Description</th>
                      <th style={tableHeaderStyle}>File</th>
                      <th style={tableHeaderStyle}>Edit</th>
                      <th style={tableHeaderStyle}>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authors.map((author) => (
                      <tr key={author._id}>
                        {/* <td>
                    <Checkbox
                      checked={selectedAuthors.includes(author._id)}
                      onChange={() => handleAuthorSelect(author._id)}
                      inputProps={{
                        "aria-label": `select author ${author.name}`,
                      }}
                    />
                   </td> */}
                        <td style={tableCellStyle}>{author.id}</td>
                        <td style={tableCellStyle}>{author.name}</td>
                        <td style={tableCellStyle}>{author.linkedIn}</td>
                        <td style={tableCellStyle}>{author.twitter}</td>
                        <td style={tableCellStyle}>{author.description}</td>
                        <td style={tableCellStyle}>
                          <img
                            src={author.file}
                            alt={author.name}
                            style={{ maxWidth: "100px" }}
                          />
                        </td>
                        <td style={tableCellStyle}>
                          <Button
                            variant="contained"
                            sx={{
                              mr: 1,
                              backgroundColor: "blue",
                              "&:hover": { backgroundColor: "darkblue" },
                              color: "white",
                            }}
                            onClick={() => handleClickOpen(author)}
                          >
                            Edit
                          </Button>
                        </td>
                        <td style={tableCellStyle}>
                          <Button
                            variant="contained"
                            sx={{
                              ml: 1,
                              backgroundColor: "red",
                              "&:hover": { backgroundColor: "darkred" },
                              color: "white",
                            }}
                            onClick={() => handleDeleteClick(author._id)} // Updated to use handleDeleteClick
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Grid>
          </Grid>

          <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this author?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDelete}>Cancel</Button>
              <Button onClick={handleConfirmDelete} color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>

        <Dialog
          open={openConfirmDialog}
          onClose={handleCancelDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {editingAuthor ? "Edit Author" : "Add Author"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Author Name"
              type="text"
              fullWidth
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              variant="standard"
            />
            <TextField
              margin="dense"
              label="LinkedIn"
              type="text"
              fullWidth
              value={linkedIn}
              onChange={(e) => setLinkedIn(e.target.value)}
              variant="standard"
            />
            <TextField
              margin="dense"
              label="Twitter"
              type="text"
              fullWidth
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              variant="standard"
            />
            <TextField
              margin="dense"
              label="Description"
              type="text"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="standard"
            />

            <input
              rel="preload"
              accept="image/*"
              id="file-input"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label htmlFor="file-input">
              <Button variant="contained" color="primary" component="span">
                Upload Image
              </Button>
            </label>
            {imagePreview && (
              <Box mt={2}>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
                <Button
                  onClick={handleCancelImage}
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 1 }}
                >
                  Remove Image
                </Button>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmit}
              color="primary"
              sx={{
                backgroundColor: "green",
                color: "white",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
                padding: "6px 16px",
                fontSize: "14px",
                borderRadius: "8px",
              }}
            >
              {editingAuthor ? "Update" : "Submit"}
            </Button>

            <Button
              onClick={handleClose}
              color="secondary"
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "darkred",
                },
                padding: "6px 16px",
                fontSize: "14px",
                borderRadius: "8px",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};

const tableHeaderStyle = {
  padding: "8px",
  // border: "1px solid #ddd",
  backgroundColor: "#191970",
  color: "white",
};

const tableCellStyle = {
  padding: "8px",
  border: "1px solid #ddd",
};

export default AddAuthor;
