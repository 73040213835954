import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import marketingLogo from "../main/img/marketing_logo.webp";
import marketiln_logo2 from "../main/img/marketiln_logo2 (1).webp";

import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import axios from "axios";
import SubscriptionsTwoToneIcon from "@mui/icons-material/SubscriptionsTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import ReCAPTCHA from "react-google-recaptcha";

function NavBar() {
  const location = useLocation();
  const [capVal, setCapVal] = useState(null); // reCAPTCHA value
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false); // State for controlling dialog open/close
  const [responseMessage, setResponseMessage] = useState(""); // State for response message
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
  const [loading, setLoading] = useState(false); // State for loading spinner
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    if (!capVal) {
      setResponseMessage("Please complete the reCAPTCHA verification.");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true); // Show loader
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/addsubscriber`,
        {
          formData: {
            ...formData,
          },
        }
      );

      if (response.status === 201) {
        setResponseMessage("Subscription successful!");
        setOpenSnackbar(true); // Show Snackbar on success
        handleClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setResponseMessage("Email is already subscribed.");
      } else {
        setResponseMessage("Error: Unable to create subscriber.");
      }
      setOpenSnackbar(true); // Show Snackbar with the error message
      setTimeout(() => {
        setResponseMessage("");
        setOpenSnackbar(false); // Optionally hide Snackbar after timeout
      }, 4000);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClick = (path) => () => {
    navigate(path);
  };

  // Function to open the dialog
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
    setFormData({ name: "", email: "" }); // Reset form data
    setCapVal(null); // Reset reCAPTCHA
  };

  const onReCAPTCHAChange = (value) => {
    setCapVal(value);
  };

  const drawerList = (
    <List
      sx={{
        width: 190,
        height: "auto",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      role="presentation"
    >
      {[
        { text: "Marketing News", path: "/marketing" },
        { text: "Blog", path: "/mediablog" },
        // { text: "Client Profile", path: "/client-profile" },
        { text: "About", path: "/about" },
        { text: "Contact Us", path: "/contact" },
      ].map(({ text, path }) => (
        <Grid item key={text} xs={12} sx={{ width: "100%" }}>
          <Button
            onClick={handleClick(path, text)} // Set button as active
            fullWidth
            sx={{
              color: location.pathname === path ? "black" : "black", // Active button text color
              backgroundColor:
                location.pathname === path ? " #4d4dff" : "transparent", // Active button background color
              "&:hover": { backgroundColor: "#ccffff", color: "#009999" }, // Hover effect
              borderRadius: "5px",
              padding: "10px", // Padding for better click area
              transition: "all 0.3s ease",
            }}
          >
            {text}
          </Button>
        </Grid>
      ))}
      {isMobile && (
        <>
          <Divider />
          <Grid container justifyContent="center" sx={{ marginY: 1, px: 7 }}>
            <ListItem button component="a" href="mailto:your-email@example.com">
              <IconButton title="Email" placement="left-start">
                <MailOutlineOutlinedIcon />
              </IconButton>
            </ListItem>
          </Grid>
        </>
      )}
    </List>
  );

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "white",
        boxShadow: 2,
        transition: "all 0.3s",
        height: isMobile ? "70px" : scrolled ? "70px" : "120px",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.3s",
        }}
      >
        <Snackbar
          open={openSnackbar}
          autoHideDuration={9000}
          onClose={() => setOpenSnackbar(false)}
          message={responseMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "green" } }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackbar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />

        <Grid container xs={12} md={10} lg={10} alignItems="center">
          <Grid
            item
            xs={2}
            md={0.5}
            lg={0.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <IconButton
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                display: { xs: "block", md: "block", lg: "flex" },
                position: "absolute",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>

          <Grid
            item
            xs={10}
            md={9}
            lg={9}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile
                ? "center"
                : scrolled
                ? "flex-start"
                : "center", // Align left in mobile, adjust for scroll in desktop
              width: "100%",
              flexDirection: { xs: "row", md: "row" }, // Keep it as a row for both views
              // ml: isMobile ? "8" : "0"
              // border:"2px solid black"
            }}
          >
            <Grid
              item
              component="img"
              src={marketiln_logo2}
              alt="Marketing Inc Logo"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: isMobile ? "45%" : scrolled ? "18%" : "25%",
                height: "auto",
                mb: { md: 0 },
                ml: { xs: 5, md: scrolled ? 0 : 12 },
                mr: isMobile ? 1 : 0, // Reduced margin in mobile view
                padding: 0,
                transition: "width 0.3s",
                cursor: "pointer",
                // border: "2px solid black", // Border for the logo
              }}
              onClick={handleLogoClick}
            />

            {/* Conditionally render Subscribe Button on Mobile */}
            {isMobile && (
              <Grid item>
                <IconButton
                  sx={{
                    color: "	 #002080",
                    fontSize: ".75rem",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                    ml: 7, // Margin left to space between logo and button
                    // border: "2px solid black", // Border for the button
                  }}
                  onClick={handleOpen} // Open dialog on click
                >
                  <SubscriptionsTwoToneIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>

          {!isMobile && (
            <Grid item xs={12} md={2.5} lg={2.5}>
              <Grid
                alignItems="center"
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  padding: "6px",
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    component="a"
                    href="mailto:your-email@example.com"
                    sx={{
                      padding: 2,
                      color: "black",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <MailOutlineOutlinedIcon />
                  </IconButton>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: 18, marginLeft: 1, marginRight: 1, mt: 2 }}
                />
                <Button
                  sx={{
                    color: "red",
                    fontSize: ".75rem",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline", color: "green" },
                  }}
                  onClick={handleOpen} // Open dialog on click
                >
                  Subscribe
                </Button>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: 18, marginLeft: 1, marginRight: 1, mt: 2 }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Toolbar>

      {!isMobile && (
        <Toolbar
          sx={{
            justifyContent: "center",
            flexWrap: "wrap",
            mr: 5,
            transition: "all 0.3s",
            display: scrolled ? "none" : "flex",
          }}
        >
          <Grid container spacing={3} justifyContent="center">
            {[
              { text: "Marketing News", path: "/marketing" },
              { text: "Blog", path: "/mediablog" },
              { text: "About", path: "/about" },
              { text: "Contact Us", path: "/contact" },
            ].map(({ text, path }) => (
              <Grid item key={text}>
                <Button
                  onClick={handleClick(path, text)} // Set button as active
                  sx={{
                    color: location.pathname === path ? "black" : "black", // Active button text color
                    backgroundColor:
                      location.pathname === path ? "	 #4d4dff" : "transparent", // Active button background color
                    "&:hover": {
                      backgroundColor: "	 #ccffff",
                      color: "	 #009999",
                    }, // Hover effect
                    borderRadius: "5px",
                    padding: "6px 12px",
                    transition: "all 0.3s ease",
                  }}
                >
                  {text}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Toolbar>
      )}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ height: "auto", justifyContent: "center" }}
      >
        {drawerList}
      </Drawer>

      {/* Dialog for Subscription */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
          />
        </DialogContent>

        <DialogActions
          sx={{ flexDirection: "column", alignItems: "center", width: "95%" }}
        >
          {/* reCAPTCHA component */}
          <ReCAPTCHA
            // 6LdxZU0qAAAAACksyRXScrhWPyxchEXmHMAYf4Ra // local key
            // 6LcYik0qAAAAAHRcFfO0daoCicudtktYweXek1Kc  server key

            sitekey="6LcYik0qAAAAAHRcFfO0daoCicudtktYweXek1Kc"
            onChange={onReCAPTCHAChange}
            sx={{ marginBottom: 2, display: "flex", justifyContent: "center" }}
          />

          {responseMessage === "Email is already subscribed." && (
            <Typography
              color="white"
              sx={{
                backgroundColor: "red",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "1rem",
                maxWidth: "100%", // Ensure responsiveness
              }}
            >
              {responseMessage}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setResponseMessage(""); // Clear the message
                  setOpenSnackbar(false); // Hide the Snackbar
                }}
                sx={{ marginLeft: 1 }} // Add margin for spacing between text and icon
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: isMobile ? "90%" : "60%",
              paddingX: 2,
              marginTop: "1",
            }}
          >
            <Button
              sx={{
                color: "white",
                backgroundColor: "red",
                minWidth: "120px", // Ensure a minimum width for the button
                whiteSpace: "nowrap", // Prevent text overflow
                "&:hover": { textDecoration: "underline", color: "red" },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              sx={{
                color: "white",
                backgroundColor: "blue",
                minWidth: "120px", // Ensure a minimum width for the button
                whiteSpace: "nowrap", // Prevent text overflow
                "&:hover": { textDecoration: "underline", color: "green" },
              }}
              onClick={handleSubmit}
              disabled={loading || !capVal}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "#ffffff" }} />
              ) : (
                "Subscribe"
              )}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={responseMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "green" } }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenSnackbar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </AppBar>
  );
}

export default NavBar;
