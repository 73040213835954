import axios from 'axios'

export const handleEditorImageUpload = async (blobInfo, progress) => {
  const formData = new FormData()
  formData.append('imagelink', blobInfo.blob()) // Sending the image as 'imagelink'

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/article-images`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          progress(percentCompleted)
        },
      }
    )
    console.log('repsone after the image upload,', response.data)
    return response.data.imagelink // The URL returned by the backend
  } catch (error) {
    console.error('Error uploading image to TinyMCE:', error)
    throw error
  }
}

export const handleEditorImageDelete = async (imageSrc) => {
  console.log('delete image:', imageSrc)
  try {
    // Perform the delete operation with `imageSrc` in the request body
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/article-images`, {
      data: {
        imagelink: imageSrc,
      },
    })
    console.log('Image deleted successfully')
  } catch (error) {
    console.error('Error deleting image:', error)
  }
}