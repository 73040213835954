import React from 'react'
import { useState, useEffect } from "react";

import Footer from "../global/footer";
import NavBar from "../global/header";
import { Grid, Typography, Box, Link, Button } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { MetadescriptionSetterForPage, TitleSetterForPage } from '../utils/MetaDescriptionSetter';



function BlogPosts() {

  const [article, setArticle] = useState("");

    const blogs = [
      {
        category: "AGENCY CULTURE",
        title:
          "Clients want agencies to deliver industry expertise, agility and empathy, new research finds",
        date: "July 16, 2024",
      },
      {
        category: "THE CONFESSIONS",
        title:
          "Confessions of a DTC investor on the difficulty of dealing with the ‘increasingly common’ founder-influencer",
        date: "July 15, 2024",
      },
      {
        category: "SPONSORED",
        title:
          "Q&A: How advertisers are optimizing CTV ad spend and measuring success",
        date: "July 10, 2024",
      },
      {
        category: "THE CONFESSIONS",
        title:
          "Confessions of a DTC investor on the difficulty of dealing with the ‘increasingly common’ founder-influencer",
        date: "July 15, 2024",
      },
      {
        category: "THE CREATOR ECONOMY",
        title: "Why longer videos are becoming more commonplace on YouTube",
        date: "July 14, 2024",
      },
      {
        category: "SPONSORED",
        title:
          "From Killabears to the Stanley Cup Playoffs, how AR drove deep audience engagement",
        date: "July 12, 2024",
      },
      {
        category: "AGENCY CULTURE",
        title:
          "Clients want agencies to deliver industry expertise, agility and empathy, new research finds",
        date: "July 16, 2024",
      },
      {
        category: "THE CONFESSIONS",
        title:
          "Confessions of a DTC investor on the difficulty of dealing with the ‘increasingly common’ founder-influencer",
        date: "July 15, 2024",
      },
    ];
  
    return (
<>
<MetadescriptionSetterForPage metadescription={"Marketingnews"} />
<TitleSetterForPage title={"Marketingnews"} />


      <Grid container spacing={2} sx={{ textAlign: "left" }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            variant="h6"
            component="div"
            textAlign="left"
            sx={{
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "800",
              marginBottom: 3,
              mt:19
            }}
          >
            LATEST NEWS
          </Typography>
        </Grid>
        {blogs.map((blog, index) => (
          <Grid item xs={12} key={index}>
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              sx={{
                color: "rgba(0, 0, 0, 0.54)",
                fontSize: "0.55rem",
                marginBottom: 1,
              }}
            >
              {blog.category}
            </Typography>
            <Typography
              variant="h8"
              component="div"
              gutterBottom
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              {blog.title}
            </Typography>
            <Typography variant="caption" color="text.secondary" gutterBottom>
              {blog.date}
            </Typography>
            {index < blogs.length - 1 && (
              <Divider sx={{ marginTop: 2, marginBottom: 0 }} />
            )}
          </Grid>
        ))}
      </Grid>
      </>
    );
  }

export const Marketingnews = () => {
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (

    <>
<MetadescriptionSetterForPage metadescription={"Marketingnews"} />
<TitleSetterForPage title={"Marketingnews"} />

    <Grid
      item
      xs={12}
      md={8}
      lg={8}
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />


      <Grid container xs={12} md={10} lg={10} paddingLeft={isMobile ? 0 : 0} justifyContent={"space-around"} alignItems={"center"}>
            <BlogPosts />
          </Grid>
      <Grid
        container
        xs={12}
        md={10}
        lg={10}
        mt={15}
        p={isMobile ? 3 : 0}
        spacing={2}
      >
        <Footer />
      </Grid>
    </Grid>
    </>
   
  )
}

export default Marketingnews