import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Button,
  Pagination,
  useMediaQuery,
  CardMedia,
  CardActions,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import NavBar from "../global/header";
import Footer from "../global/footer";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import imageCompression from "browser-image-compression";
import personlogo from "../main/img/personlogo.jpg";
import {
  MetadescriptionSetterForPage,
  TitleSetterForPage,
} from "../utils/MetaDescriptionSetter";

const AuthorPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [articles, setArticles] = useState([]);
  const [author, setAuthor] = useState(null);
  const { authorName } = useParams();
  const [article, setArticle] = useState([]);

  const [page, setPage] = useState(1);
  const itemsPerPage = 4; // Number of articles per page

  // Fetch articles by author
  useEffect(() => {
    const fetchArticlesByAuthor = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/cards/getarticlesbyauthor/${authorName}`
        );
        setArticles(response.data);
      } catch (error) {
        console.error("Error fetching articles by author:", error);
      }
    };

    const fetchAuthorDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/getAllAuthors`
        );
        const fetchedAuthors = response.data.authors;
        const currentAuthor = fetchedAuthors.find(
          (auth) => auth.authorName === authorName
        );
        setAuthor(currentAuthor);

        // console.log("sasa", currentAuthor);
      } catch (error) {
        console.error("Error fetching author details:", error);
      }
    };

    fetchArticlesByAuthor();
    fetchAuthorDetails();
  }, [authorName]);

  // Pagination logic
  const totalPages = Math.ceil(articles.length / itemsPerPage);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const currentArticles = articles.slice(startIndex, startIndex + itemsPerPage);
  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];

    // Compression options
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      const formData = new FormData();
      formData.append("file", compressedFile);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/article-images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Image uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <>
      <MetadescriptionSetterForPage metadescription={article.metadescription} />
      <TitleSetterForPage title={"AutorPage"} />

      <Grid
        container
        sx={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <NavBar />

        <Container maxWidth="lg" sx={{ marginTop: 19 }}>
          <Grid container spacing={4}>
            {/* Blog Posts Section */}
            <Grid item xs={12} md={8}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: 2 }}
              >
                Posts by {authorName}
              </Typography>
              <Grid container spacing={3}>
                {currentArticles.map((article) => (
                  <Grid item xs={12} md={6} key={article._id}>
                    <Link
                      to={`/seo/${article.slug}`}
                      style={{ textDecoration: "none " }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: isMobile ? "row" : "column",
                          transition: isMobile ? "none" : "transform 0.3s",
                          "&:hover": {
                            transform: "scale(1.03)",
                          },
                          height: isMobile ? "auto" : 380,
                          borderRadius: 2,
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                      >
                        <CardMedia
                          component="img"
                          src={article.imagelink}
                          alt={article.headertext}
                          sx={{
                            height: isMobile ? 120 : 200,
                            width: isMobile ? "40%" : "100%",
                            objectFit: isMobile ? "contain" : "fill",
                          }}
                        />
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            padding: 3,
                            flexGrow: 1,
                            // backgroundColor: "#f9f9f9",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: theme.palette.warning.main,
                              borderRadius: 1,
                              px: 0,
                              py: 0.5,
                              fontWeight: "bold",
                              mb: 1,
                              display: "inline-block",
                              textAlign: "left",
                            }}
                          >
                            {article.category}
                          </Typography>
                          <Typography
                            display={"block"}
                            variant="caption"
                            sx={{
                              fontWeight: "600",
                              textAlign: "left",
                              fontSize: isMobile ? "1rem" : "1rem",
                              lineHeight: isMobile ? "1.2" : "1.4",
                              marginBottom: isMobile ? "8px" : "14px",
                              color: "#333",
                              height: isMobile ? "auto" : "48px",
                            }}
                          >
                            {article.headertext}
                          </Typography>
                        </CardContent>

                        {!isMobile && (
                          <CardActions
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              pb: 2,
                              backgroundColor: "#fafafa",
                            }}
                          >
                            <Typography
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: "bold",
                              }}
                            >
                              Read more »
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "#777",
                              }}
                            >
                              <RemoveRedEyeIcon
                                sx={{ mr: 0.5, color: "#555" }}
                              />
                              {article.clicks}
                            </Box>
                          </CardActions>
                        )}
                      </Grid>
                    </Link>
                  </Grid>
                ))}
              </Grid>
              {/* Pagination */}
              <Box
                sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
              >
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </Box>
            </Grid>

            {/* Author Details Section */}
            <Grid item xs={12} md={4}>
              <Card sx={{ padding: 2, textAlign: "center" }}>
                {/* <Avatar
                src={author?.file || "https://via.placeholder.com/150"}
                alt={authorName}
                sx={{
                  width: 150,
                  height: 150,
                  margin: "0 auto",
                  objectFit: "cover", // Ensure the image fills the Avatar correctly
                  borderRadius: "50%", // Make sure the Avatar is circular
                  border: "2px solid #ddd", // Optional: add a border for better appearance
                  marginTop: "15",
                }}
              /> */}

                <Avatar
                  // src={author?.file || "https://via.placeholder.com/150"}
                  src={author?.file || personlogo}
                  alt={authorName}
                  sx={{
                    width: 150,
                    height: 190,
                    margin: "0 auto",
                    objectFit: "cover", // Ensures the image covers the Avatar area
                    borderRadius: "0%", // Ensures the avatar remains circular
                    border: "2px solid #ddd", // Optional border for appearance
                    padding: 0, // Ensure there's no padding inside the Avatar
                  }}
                />

                <Typography variant="h5" sx={{ marginTop: 2 }}>
                  {authorName}
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginTop: 1 }}
                >
                  {/* console.log("asdasaas", description); */}

                  {author?.description || "No description available."}
                </Typography>
                <Divider sx={{ marginY: 2 }} />
                <Box>
                  <Button
                    startIcon={<LinkedInIcon />}
                    href={author?.linkedIn || "https://www.linkedin.com"}
                    target="_blank"
                    sx={{ marginRight: 1 }}
                  >
                    LinkedIn
                  </Button>
                  <Button
                    startIcon={<TwitterIcon />}
                    href={author?.twitter || "https://www.twitter.com"}
                    target="_blank"
                  >
                    Twitter
                  </Button>
                </Box>
                {/* <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ marginTop: 20 }}
              /> */}
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default AuthorPage;
