// import {
//   Box,
//   Grid,
//   IconButton,
//   Paper,
//   Tooltip,
//   Typography,
//   useTheme,
//   Button,
// } from "@mui/material";
// import { useContext } from "react";

// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// import { Navigate, useNavigate } from "react-router-dom";
// import DeleteIcon from "@mui/icons-material/Delete";
// import LogoutIcon from "@mui/icons-material/Logout";

// export const HeaderTopbar = () => {
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     // localStorage.clear();
//     navigate("/");
//   };

//   return (
//     <Box display="flex" justifyContent="flex-end" alignItems="center">
//       <Box display="flex" justifyContent="flex-end" alignItems="center">
//         <Grid item xs={1} sm={6} md={4}>
//           <Tooltip title="Logout">
//             {/* <IconButton onClick={handleLogout}>
//             <PersonOutlineOutlinedIcon sx={{color:'white'}}/>
//           </IconButton> */}
//             <Button
//               onClick={handleLogout}
//               startIcon={<LogoutIcon />}
//               sx={{ color: "blue", textTransform: "none" }}
//             >
//               Logout
//             </Button>
//           </Tooltip>
//         </Grid>
//       </Box>
//     </Box>
//   );
// };

// export default HeaderTopbar;


import {
  Box,
  Grid,
  Tooltip,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

export const HeaderTopbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear everything from localStorage except username and password
    const UserName = localStorage.getItem("UserName");
    const password = localStorage.getItem("password");

    // Clear all storage
    localStorage.clear();

    // Re-set the username and password back into localStorage
    if (UserName && password) {
      localStorage.setItem("UserName", UserName);
      localStorage.setItem("password", password);
    }

    // Redirect to login or home page
    navigate("/");
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Grid item xs={1} sm={6} md={4}>
          <Tooltip title="Logout">
            <Button
              onClick={handleLogout}
              startIcon={<LogoutIcon />}
              sx={{ color: "blue", textTransform: "none" }}
            >
              Logout
            </Button>
          </Tooltip>
        </Grid>
      </Box>
    </Box>
  );
};

export default HeaderTopbar;
