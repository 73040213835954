// import React, { useState, useEffect } from "react";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import NavBar from "../global/header";
// import { Box, Button, CardMedia, CardActions, Paper } from "@mui/material";
// import MobileMarketingImg from "../main/img/mobile-marketing.jpg";
// import SocialMediaMarketingImg from "../main/img/social-media-marketing.jpg";
// import ContentMarketingImg from "../main/img/content-marketing.jpg";
// import BuisnessImg from "../main/img/Buisness.jpg";
// import DigitalImg from "../main/img/Digital.png";
// import EmailMarketingImg from "../main/img/email-marketing.jpg";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import Footer from "../global/footer";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import Container from "@mui/material/Container";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// const items = Array.from({ length: 100 }, (_, index) => `Item ${index + 1}`);

// function ArticleGrid({ articles }) {
//   return (
//     <Grid container spacing={4} mt={8} justifyContent="center">
//       {articles.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={4}
//           key={index}
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <Link
//             to={`/seo/${article.slug}`}
//             variant="body2"
//             color="primary"
//             sx={{
//               display: "inline-block", // Use inline-block to ensure proper alignment without flex effects
//               textDecoration: "none",
//               textAlign: "left",
//               mt: 5,
//               border: "2px solid black",
//               fontWeight: "bold",
//             }}
//           >
//             <Card
//               sx={{
//                 height: 430, // Set a fixed height for all cards
//                 width: "100%", // Ensure full width within its container
//                 maxWidth: 345, // Optional: max width for responsive design
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between", // Align content within the card
//                 p: 2,
//               }}
//             >
//               <CardMedia
//                 component="img"
//                 src={article.imagelink}
//                 alt={article.headertext}
//                 sx={{
//                   height: 190, // Set a consistent height for images
//                 }}
//               />
//               <CardContent
//                 sx={{
//                   flexGrow: 1, // Ensure CardContent takes available space
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between", // Space text and button evenly
//                 }}
//               >
//                 <Grid
//                   sx={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     alignItems: "center",
//                     mb: 1,
//                   }}
//                 >
//                   <Typography
//                     variant="body2"
//                     color="white"
//                     sx={{
//                       backgroundColor: "primary.light",
//                       borderRadius: 1,
//                       px: 1,
//                       py: 0.5,
//                       fontWeight: "bold",
//                       textAlign: "left",
//                     }}
//                   >
//                     {article.category}
//                   </Typography>
//                 </Grid>
//                 <Typography
//                   variant="h6"
//                   sx={{
//                     textAlign: "left",
//                     fontWeight: "bold",
//                   }}
//                   gutterBottom
//                 >
//                   {article.headertext}
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="textSecondary"
//                   sx={{ textAlign: "left" }}
//                 >
//                   {article.date}
//                 </Typography>
//                 {/* <Typography
//                   variant="body2"
//                   color="textSecondary"
//                   sx={{ textAlign: "left", mt: 1, fontWeight: "bold" }}
//                 >
//                   {article.shortdescription}
//                 </Typography> */}
//               </CardContent>
//               <CardActions
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between", // Space out actions
//                 }}
//               >
//                 <Link
//                   to={`/seo/${article.slug}`}
//                   variant="body2"
//                   color="primary"
//                   sx={{
//                     display: "inline-block",
//                     textDecoration: "none",
//                     textAlign: "left",
//                     mt: 5,
//                     border: "2px solid black",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   Read more
//                   {/* <span style={{ marginRight: 210, color: "black" }}>»»</span> */}
//                 </Link>
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     textAlign: "left",
//                     mt: 5,
//                     alignSelf: "flex-start",
//                   }}
//                 >
//                   <RemoveRedEyeIcon
//                     sx={{
//                       marginLeft: 0.5,
//                       marginRight: 0.5,
//                       color: "grey.900",
//                       opacity: 0.7,
//                     }}
//                   />
//                   {article.clicks}
//                 </Typography>
//               </CardActions>
//             </Card>
//           </Link>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// function PaginatedList({ count }) {
//   const [page, setPage] = useState(1); // Start at page 1
//   const itemsPerPage = 25;

//   const handleChange = (event, value) => {
//     setPage(value);
//   };

//   const totalPages = Math.ceil(count / itemsPerPage); // Calculate total pages
// }

// export const Media = () => {
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const [articles, setArticle] = useState([]);
//   const [articleCount, setArticleCount] = useState(0);

//   const handleCategorySelect = (categoryId) => {
//     setSelectedCategory(categoryId);
//   };

//   const [page, setPage] = useState(1); // Start at page 1
//   const itemsPerPage = 25;

//   const handleChange = (event, value) => {
//     setPage(value);
//   };

//   const totalPages = Math.ceil(articleCount / itemsPerPage);

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data;
//         setArticle(articlesArray);
//         const extractedCategories = Array.from(
//           new Set(response.data.map((article) => article.category))
//         )
//           .filter((category) => category !== "N/A") // Filter out "N/A" if necessary
//           .map((category, index) => ({
//             id: `category-${category.toLowerCase().replace(/\s+/g, "-")}`,
//             name: category,
//           }));

//         setArticleCount(articlesArray.length);
//         setCategories(extractedCategories);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const filteredArticles = selectedCategory
//     ? articles.filter((article) => article.tag === selectedCategory)
//     : articles;

//   console.log("====================================");
//   console.log(filteredArticles);
//   console.log("====================================");

//   return (
//     <Grid
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />

//       <Grid item xs={12} md={10} lg={10} mt={isMobile ? 10 : 20}>
//         <Typography
//           variant="h1"
//           component="h1"
//           sx={{
//             fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
//             fontWeight: "bold",
//             textAlign: "center",
//             color: "blue", // Attractive teal color
//           }}
//         >
//           Blogs
//         </Typography>
//       </Grid>
//       {/*
//       <CategoriesBar
//         onCategorySelect={handleCategorySelect}
//         categories={categories}
//       /> */}

//       <Grid container xs={12} md={10} lg={10}>
//         <ArticleGrid articles={filteredArticles} />
//       </Grid>

//       {/* <PaginatedList count={articleCount}/> */}
//       <Pagination
//         count={totalPages} // Set the count to totalPages
//         page={page}
//         onChange={handleChange}
//         variant="text"
//         shape="circular"
//         color="primary"
//         siblingCount={1}
//         boundaryCount={1}
//         showFirstButton
//         showLastButton
//         sx={{
//           "& .MuiPaginationItem-root": {
//             fontSize: { xs: "0.75rem", sm: "1rem" },
//           },
//           mt: 2,
//         }}
//       />

//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default Media;

// import React, { useState, useEffect } from "react";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import NavBar from "../global/header";
// import { CardMedia, CardActions, Pagination, Container } from "@mui/material";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import Footer from "../global/footer";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// function ArticleGrid({ articles }) {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return (
//     <Container maxWidth="lg">
//       <Grid
//         container
//         spacing={2}
//         mt={8}
//         justifyContent="center"
//         alignItems="flex-start"
//       >
//         {articles.map((article, index) => (
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={4}
//             key={index}
//             // sx={{
//             //   display: "flex",
//             //   flexDirection: isMobile ? "row" : "column", // Adjust layout for mobile
//             //   alignItems: "center",
//             // }}
//             sx={{ position: "relative", cursor: "pointer" }}
//           >
//             <Link
//               to={`/seo/${article.slug}`}
//               style={{
//                 textDecoration: "none",
//                 width: "100%",
//               }}
//             >
//               <Grid
//                 sm={10}
//                 sx={{
//                   display: "flex",
//                   flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
//                   justifyContent: "space-around",
//                   height: isMobile ? "auto" : 400, // Fixed height for desktop view
//                   width: isMobile ? "auto" : "auto",
//                   boxShadow: isMobile ? 0 : 0,
//                   padding: isMobile ? 0 : 1, // Adjust padding for mobile
//                   transition: isMobile ? "none" : "transform 0.3s",
//                   "&:hover": {
//                     transform: "scale(1.03)",
//                   },
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   src={article.imagelink}
//                   alt={article.headertext}
//                   sx={{
//                     height: isMobile ? 100 : 200, // Adjust image height for mobile
//                     width: isMobile ? "40%" : "100%", // Set fixed width for mobile
//                     objectFit: isMobile ? "contain" : "fill", // Ensure proper fit
//                     borderRadius: "2px",
//                   }}
//                 />
//                 <CardContent
//                   // sx={{
//                   //   flexGrow: 1,
//                   //   display: "flex",
//                   //   flexDirection: "column",
//                   //   justifyContent: "space-between",
//                   //   padding: isMobile ? "8px" : "16px", // Adjust padding for mobile
//                   // }}

//                   sx={{
//                     flexGrow: 1,
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     width: "100%", // Ensure it takes full width of the card
//                     padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
//                     boxSizing: "border-box",
//                     height: isMobile ? "auto" : "100%", // Adjust height for desktop view
//                   }}
//                 >
//                   <Typography
//                     variant="body2"
//                     color="red"
//                     sx={{
//                       // backgroundColor: "primary.light",
//                       borderRadius: 1,
//                       px: 1,
//                       py: 0.5,
//                       fontWeight: "bold",
//                       mb: 1,
//                       textAlign: "left",
//                     }}
//                   >
//                     {article.category}
//                   </Typography>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontWeight: "bold",
//                       textAlign: "left",
//                       fontSize: "1.1rem",
//                     }}
//                   >
//                     {article.headertext}
//                   </Typography>
//                 </CardContent>

//                 {!isMobile && (
//                   <CardActions
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       px: 2,
//                       pb: 1,
//                     }}
//                   >
//                     <Typography
//                       to={`/seo/${article.slug}`}
//                       sx={{
//                         textDecoration: "none",
//                         color: "primary.main",
//                         "&:hover": { textDecoration: "underline" },
//                         fontWeight: "bold",
//                       }}
//                     >
//                       Read more »
//                     </Typography>
//                     <Typography
//                       variant="body2"
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         color: "grey.800",
//                       }}
//                     >
//                       <RemoveRedEyeIcon
//                         sx={{
//                           mr: 0.5,
//                           color: "grey.700",
//                           fontSize: "1.1rem",
//                         }}
//                       />
//                       {article.clicks}
//                     </Typography>
//                   </CardActions>
//                 )}
//               </Grid>
//             </Link>
//           </Grid>
//         ))}
//       </Grid>
//     </Container>
//   );
// }

// export const Media = () => {
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const [articles, setArticle] = useState([]);
//   const [articleCount, setArticleCount] = useState(0);
//   const [page, setPage] = useState(1);
//   const itemsPerPage = 25;

//   const handleCategorySelect = (categoryId) => {
//     setSelectedCategory(categoryId);
//   };

//   const handleChange = (event, value) => {
//     setPage(value);
//   };

//   const totalPages = Math.ceil(articleCount / itemsPerPage);

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data;
//         setArticle(articlesArray);
//         const extractedCategories = Array.from(
//           new Set(response.data.map((article) => article.category))
//         )
//           .filter((category) => category !== "N/A")
//           .map((category) => ({
//             id: `category-${category.toLowerCase().replace(/\s+/g, "-")}`,
//             name: category,
//           }));

//         setArticleCount(articlesArray.length);
//         setCategories(extractedCategories);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const filteredArticles = selectedCategory
//     ? articles.filter((article) => article.tag === selectedCategory)
//     : articles;

//   return (
//     <Grid
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />
//       <Grid item xs={12} md={10} lg={10} mt={isMobile ? 10 : 20}>
//         <Typography
//           variant="h1"
//           component="h1"
//           sx={{
//             fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
//             fontWeight: "bold",
//             textAlign: "center",
//             color: "blue",
//           }}
//         >
//           Blogs
//         </Typography>
//       </Grid>
//       <Grid container xs={12} md={10} lg={10}>
//         <ArticleGrid articles={filteredArticles} />
//       </Grid>
//       <Pagination
//         count={totalPages}
//         page={page}
//         onChange={handleChange}
//         variant="text"
//         shape="circular"
//         color="primary"
//         siblingCount={1}
//         boundaryCount={1}
//         showFirstButton
//         showLastButton
//         sx={{
//           "& .MuiPaginationItem-root": {
//             fontSize: { xs: "0.75rem", sm: "1rem" },
//           },
//           mt: 2,
//         }}
//       />
//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default Media;

// import React, { useState, useEffect } from "react";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button"; // Import Button for category selection
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import NavBar from "../global/header";
// import { CardMedia, CardActions, Pagination, Container } from "@mui/material";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import Footer from "../global/footer";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// // import Box from "@mui/material";
// import { Box} from "@mui/material";


// function ArticleGrid({ articles }) {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return (
//     <Container maxWidth="lg">
//       <Grid
//         container
//         spacing={2}
//         mt={8}
//         justifyContent="center"
//         alignItems="flex-start"
//       >
//         {articles.map((article, index) => (
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={4}
//             key={index}
//             sx={{ position: "relative", cursor: "pointer" }}
//           >
//             <Link
//               to={`/seo/${article.slug}`}
//               style={{
//                 textDecoration: "none",
//                 width: "100%",
//               }}
//             >
//               {/* <Grid
//                 sm={10}
//                 sx={{
//                   display: "flex",
//                   flexDirection: isMobile ? "row" : "column",
//                   justifyContent: "space-around",
//                   height: isMobile ? "auto" : 400,
//                   width: isMobile ? "auto" : "auto",
//                   boxShadow: isMobile ? 0 : 0,
//                   padding: isMobile ? 0 : 1,
//                   transition: isMobile ? "none" : "transform 0.3s",
//                   "&:hover": {
//                     transform: "scale(1.03)",
//                   },
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   src={article.imagelink}
//                   alt={article.headertext}
//                   sx={{
//                     height: isMobile ? 100 : 200,
//                     width: isMobile ? "40%" : "100%",
//                     objectFit: isMobile ? "contain" : "fill",
//                     borderRadius: "2px",
//                   }}
//                 />
//                 <CardContent
//                   sx={{
//                     flexGrow: 1,
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     width: "100%",
//                     padding: isMobile ? 1 : 2,
//                     boxSizing: "border-box",
//                     height: isMobile ? "auto" : "100%",
//                   }}
//                 >
//                   <Typography
//                     variant="body2"
//                     color="red"
//                     sx={{
//                       borderRadius: 1,
//                       px: 1,
//                       py: 0.5,
//                       fontWeight: "bold",
//                       mb: 1,
//                       textAlign: "left",
//                     }}
//                   >
//                     {article.category}
//                   </Typography>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontWeight: "bold",
//                       textAlign: "left",
//                       fontSize: "1.1rem",
//                     }}
//                   >
//                     {article.headertext}
//                   </Typography>
//                 </CardContent>

//                 {!isMobile && (
//                   <CardActions
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       px: 2,
//                       pb: 1,
//                     }}
//                   >
//                     <Typography
//                       to={`/seo/${article.slug}`}
//                       sx={{
//                         textDecoration: "none",
//                         color: "primary.main",
//                         "&:hover": { textDecoration: "underline" },
//                         fontWeight: "bold",
//                       }}
//                     >
//                       Read more »
//                     </Typography>
//                     <Typography
//                       variant="body2"
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         color: "grey.800",
//                       }}
//                     >
//                       <RemoveRedEyeIcon
//                         sx={{
//                           mr: 0.5,
//                           color: "grey.700",
//                           fontSize: "1.1rem",
//                         }}
//                       />
//                       {article.clicks}
//                     </Typography>
//                   </CardActions>
//                 )}
//               </Grid> */}

//               <Grid
//                 item
//                 xs={12}
//                 sx={{
//                   display: "flex",
//                   flexDirection: isMobile ? "row" : "column",
//                   transition: isMobile ? "none" : "transform 0.3s",
//                   "&:hover": {
//                     transform: "scale(1.03)",
//                   },
//                   // width: "100%",
//                   // maxWidth: "100%",
//                   height: isMobile ? "auto" : 380, // Set fixed height for desktop
//                   borderRadius: 2,
//                   overflow: "hidden",
//                   cursor: "pointer",
//                   // mx: "auto",
//                   // border: "2px solid black",
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   src={article.imagelink}
//                   alt={article.headertext}
//                   sx={{
//                     height: isMobile ? 120 : 200,
//                     width: isMobile ? "40%" : "100%",
//                     objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
//                   }}
//                 />
//                 <CardContent
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     padding: 3,
//                     flexGrow: 1, // Ensures equal height distribution
//                     backgroundColor: "#f9f9f9",
//                   }}
//                 >
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       // backgroundColor: "#e0f7fa",
//                       color: theme.palette.warning.main,
//                       borderRadius: 1,
//                       px: 0,
//                       py: 0.5,
//                       fontWeight: "bold",
//                       mb: 1,
//                       display: "inline-block",
//                       textAlign: "left",
//                     }}
//                   >
//                     {article.category}
//                   </Typography>
//                   <Typography
//                     display={"block"}
//                     variant="caption" // Changed to h6 for better visibility on desktop
//                     sx={{
//                       fontWeight: "600",
//                       textAlign: "left",
//                       fontSize: isMobile ? "1rem" : "1rem", // Responsive font size
//                       lineHeight: isMobile ? "1.2" : "1.4", // Adjust line height for readability
//                       marginBottom: isMobile ? "8px" : "14px",
//                       color: "#333",
//                       height: isMobile ? "auto" : "48px", // Fixed height for desktop
//                       // overflow: "hidden",
//                       // textOverflow: "ellipsis", // Add ellipsis for overflow text
//                       // whiteSpace: "nowrap", // Prevent wrapping of the text
//                     }}
//                   >
//                     {article.headertext}
//                   </Typography>
//                 </CardContent>

//                 {!isMobile && (
//                   <CardActions
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       // px: 3,
//                       pb: 2,
//                       backgroundColor: "#fafafa",
//                     }}
//                   >
//                     <Typography
//                       sx={{
//                         color: theme.palette.primary.main,
//                         fontWeight: "bold",
//                       }}
//                     >
//                       Read more »
//                     </Typography>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         color: "#777",
//                       }}
//                     >
//                       <RemoveRedEyeIcon sx={{ mr: 0.5, color: "#555" }} />
//                       {article.clicks}
//                     </Box>
//                   </CardActions>
//                 )}
//               </Grid>
//             </Link>
//           </Grid>
//         ))}
//       </Grid>
//     </Container>
//   );
// }

// export const Media = () => {
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const [articles, setArticle] = useState([]);
//   const [articleCount, setArticleCount] = useState(0);
//   const [page, setPage] = useState(1);
//   const itemsPerPage = 25;

//   const handleCategorySelect = (categoryId) => {
//     setSelectedCategory(categoryId === "all" ? null : categoryId);
//   };

//   const handleChange = (event, value) => {
//     setPage(value);
//   };

//   const totalPages = Math.ceil(articleCount / itemsPerPage);

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data;
//         setArticle(articlesArray);
//         const extractedCategories = Array.from(
//           new Set(response.data.map((article) => article.category))
//         )
//           .filter((category) => category !== "N/A")
//           .map((category) => ({
//             id: category,
//             name: category,
//           }));

//         setArticleCount(articlesArray.length);
//         setCategories(extractedCategories);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const filteredArticles = selectedCategory
//     ? articles.filter((article) => article.category === selectedCategory)
//     : articles;

//   return (
//     <Grid
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />
//       <Grid item xs={12} md={10} lg={10} mt={isMobile ? 10 : 20}>
//         <Typography
//           variant="h1"
//           component="h1"
//           sx={{
//             fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
//             fontWeight: "bold",
//             textAlign: "center",
//             color: "blue",
//           }}
//         >
//           Blogs
//         </Typography>
//       </Grid>

//       {/* Category Buttons */}
//       <Grid item xs={12} md={10} lg={10} sx={{ mt: 4 }}>
//         <Grid container justifyContent="center" spacing={2}>
//           <Grid item>
//             <Button
//               variant={selectedCategory === null ? "contained" : "outlined"}
//               color="primary"
//               onClick={() => handleCategorySelect("all")}
//             >
//               All
//             </Button>
//           </Grid>
//           {categories.map((category) => (
//             <Grid item key={category.id}>
//               <Button
//                 variant={
//                   selectedCategory === category.id ? "contained" : "outlined"
//                 }
//                 color="primary"
//                 onClick={() => handleCategorySelect(category.id)}
//               >
//                 {category.name}
//               </Button>
//             </Grid>
//           ))}
//         </Grid>
//       </Grid>

//       {/* Blog Articles */}
//       <Grid container xs={12} md={10} lg={10}>
//         <ArticleGrid articles={filteredArticles} />
//       </Grid>

//       <Pagination
//         count={totalPages}
//         page={page}
//         onChange={handleChange}
//         variant="text"
//         shape="circular"
//         color="primary"
//         siblingCount={1}
//         boundaryCount={1}
//         showFirstButton
//         showLastButton
//         sx={{
//           "& .MuiPaginationItem-root": {
//             fontSize: { xs: "0.75rem", sm: "1rem" },
//           },
//           mt: 2,
//         }}
//       />

//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default Media;


// import React, { useState, useEffect } from "react";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button"; // Import Button for category selection
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import NavBar from "../global/header";
// import { CardMedia, CardActions, Pagination, Container } from "@mui/material";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import Footer from "../global/footer";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import { Box } from "@mui/material";

// function ArticleGrid({ articles }) {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return (
//     <Container maxWidth="lg">
//       <Grid
//         container
//         spacing={2}
//         mt={8}
//         justifyContent="center"
//         alignItems="flex-start"
//       >
//         {articles.map((article, index) => (
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={4}
//             key={index}
//             sx={{ position: "relative", cursor: "pointer" }}
//           >
//             <Link
//               to={`/seo/${article.slug}`}
//               style={{
//                 textDecoration: "none",
//                 width: "100%",
//               }}
//             >
//               <Grid
//                 item
//                 xs={12}
//                 sx={{
//                   display: "flex",
//                   flexDirection: isMobile ? "row" : "column",
//                   transition: isMobile ? "none" : "transform 0.3s",
//                   "&:hover": {
//                     transform: "scale(1.03)",
//                   },
//                   height: isMobile ? "auto" : 380,
//                   borderRadius: 2,
//                   overflow: "hidden",
//                   cursor: "pointer",
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   src={article.imagelink}
//                   alt={article.headertext}
//                   sx={{
//                     height: isMobile ? 120 : 200,
//                     width: isMobile ? "40%" : "100%",
//                     objectFit: isMobile ? "contain" : "fill",
//                   }}
//                 />
//                 <CardContent
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     padding: 3,
//                     flexGrow: 1,
//                     backgroundColor: "#f9f9f9",
//                   }}
//                 >
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       color: theme.palette.warning.main,
//                       borderRadius: 1,
//                       px: 0,
//                       py: 0.5,
//                       fontWeight: "bold",
//                       mb: 1,
//                       display: "inline-block",
//                       textAlign: "left",
//                     }}
//                   >
//                     {article.category}
//                   </Typography>
//                   <Typography
//                     display={"block"}
//                     variant="caption"
//                     sx={{
//                       fontWeight: "600",
//                       textAlign: "left",
//                       fontSize: isMobile ? "1rem" : "1rem",
//                       lineHeight: isMobile ? "1.2" : "1.4",
//                       marginBottom: isMobile ? "8px" : "14px",
//                       color: "#333",
//                       height: isMobile ? "auto" : "48px",
//                     }}
//                   >
//                     {article.headertext}
//                   </Typography>
//                 </CardContent>

//                 {!isMobile && (
//                   <CardActions
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       pb: 2,
//                       backgroundColor: "#fafafa",
//                     }}
//                   >
//                     <Typography
//                       sx={{
//                         color: theme.palette.primary.main,
//                         fontWeight: "bold",
//                       }}
//                     >
//                       Read more »
//                     </Typography>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         color: "#777",
//                       }}
//                     >
//                       <RemoveRedEyeIcon sx={{ mr: 0.5, color: "#555" }} />
//                       {article.clicks}
//                     </Box>
//                   </CardActions>
//                 )}
//               </Grid>
//             </Link>
//           </Grid>
//         ))}
//       </Grid>
//     </Container>
//   );
// }

// export const Media = () => {
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const [articles, setArticle] = useState([]);
//   const [articleCount, setArticleCount] = useState(0);
//   const [page, setPage] = useState(1);
//   const itemsPerPage = 9; // Set to 9 blogs per page

//   const handleCategorySelect = (categoryId) => {
//     setSelectedCategory(categoryId === "all" ? null : categoryId);
//     setPage(1); // Reset to page 1 when changing categories
//   };

//   const handleChange = (event, value) => {
//     setPage(value);
//   };

//   const totalPages = Math.ceil(articleCount / itemsPerPage);

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data;
//         setArticle(articlesArray);
//         const extractedCategories = Array.from(
//           new Set(response.data.map((article) => article.category))
//         )
//           .filter((category) => category !== "N/A")
//           .map((category) => ({
//             id: category,
//             name: category,
//           }));

//         setArticleCount(articlesArray.length);
//         setCategories(extractedCategories);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   // Filtered articles for pagination and category selection
//   const filteredArticles = selectedCategory
//     ? articles.filter((article) => article.category === selectedCategory)
//     : articles;

//   // Slice articles for current page
//   const paginatedArticles = filteredArticles.slice(
//     (page - 1) * itemsPerPage,
//     page * itemsPerPage
//   );

//   return (
//     <Grid
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />
//       <Grid item xs={12} md={10} lg={10} mt={isMobile ? 10 : 20}>
//         <Typography
//           variant="h1"
//           component="h1"
//           sx={{
//             fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
//             fontWeight: "bold",
//             textAlign: "center",
//             color: "blue",
//           }}
//         >
//           Blogs
//         </Typography>
//       </Grid>

//       {/* Category Buttons */}
//       <Grid item xs={12} md={10} lg={10} sx={{ mt: 4 }}>
//         <Grid container justifyContent="center" spacing={2}>
//           <Grid item>
//             <Button
//               variant={selectedCategory === null ? "contained" : "outlined"}
//               color="primary"
//               onClick={() => handleCategorySelect("all")}
//             >
//               All
//             </Button>
//           </Grid>
//           {categories.map((category) => (
//             <Grid item key={category.id}>
//               <Button
//                 variant={
//                   selectedCategory === category.id ? "contained" : "outlined"
//                 }
//                 color="primary"
//                 onClick={() => handleCategorySelect(category.id)}
//               >
//                 {category.name}
//               </Button>
//             </Grid>
//           ))}
//         </Grid>
//       </Grid>

//       {/* Blog Articles */}
//       <Grid container xs={12} md={10} lg={10}>
//         <ArticleGrid articles={paginatedArticles} />
//       </Grid>

//       {/* Pagination */}
//       <Pagination
//         count={totalPages}
//         page={page}
//         onChange={handleChange}
//         variant="text"
//         shape="circular"
//         color="primary"
//         siblingCount={1}
//         boundaryCount={1}
//         showFirstButton
//         showLastButton
//         sx={{
//           "& .MuiPaginationItem-root": {
//             fontSize: { xs: "0.75rem", sm: "1rem" },
//           },
//           mt: 2,
//         }}
//       />

//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default Media;


import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Pagination from "@mui/material/Pagination";
import Container from "@mui/material/Container";
import NavBar from "../global/header";
import { useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Footer from "../global/footer";
import axios from "axios";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { MetadescriptionSetterForPage, TitleSetterForPage } from "../utils/MetaDescriptionSetter";


function ArticleGrid({ articles }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [article, setArticle] = useState("");
  return (
<>
    <MetadescriptionSetterForPage metadescription={"Media"} />
    <TitleSetterForPage title={"Media"} />

    <Container maxWidth="lg">
      <Grid container spacing={2} mt={8} justifyContent="center" alignItems="flex-start">
        {articles.map((article, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ position: "relative", cursor: "pointer" }}>
            <Link to={`/seo/${article.slug}`} style={{ textDecoration: "none", width: "100%" }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "column",
                  transition: isMobile ? "none" : "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.03)",
                  },
                  height: isMobile ? "auto" : 380,
                  borderRadius: 2,
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <CardMedia
                  component="img"
                  src={article.imagelink}
                  alt={article.headertext}
                  sx={{
                    height: isMobile ? 120 : 200,
                    width: isMobile ? "40%" : "100%",
                    objectFit: isMobile ? "contain" : "fill",
                  }}
                />
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: 3,
                    flexGrow: 1,
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.warning.main,
                      borderRadius: 1,
                      px: 0,
                      py: 0.5,
                      fontWeight: "bold",
                      mb: 1,
                      display: "inline-block",
                      textAlign: "left",
                    }}
                  >
                    {article.category}
                  </Typography>
                  <Typography
                    display={"block"}
                    variant="caption"
                    sx={{
                      fontWeight: "600",
                      textAlign: "left",
                      fontSize: isMobile ? "1rem" : "1rem",
                      lineHeight: isMobile ? "1.2" : "1.4",
                      marginBottom: isMobile ? "8px" : "14px",
                      color: "#333",
                      height: isMobile ? "auto" : "48px",
                    }}
                  >
                    {article.headertext}
                  </Typography>
                </CardContent>

                {!isMobile && (
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      pb: 2,
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      }}
                    >
                      Read more »
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#777",
                      }}
                    >
                      <RemoveRedEyeIcon sx={{ mr: 0.5, color: "#555" }} />
                      {article.clicks}
                    </Box>
                  </CardActions>
                )}
              </Grid>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
    </>
  );
}

export const Media = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 9; // Set to 6 blogs per page
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Handle category selection and reset pagination
  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId === "all" ? null : categoryId);
    setPage(1); // Reset to page 1 when changing categories
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        const articlesArray = response.data;
        setArticles(articlesArray);
        const extractedCategories = Array.from(
          new Set(articlesArray.map((article) => article.category))
        )
          .filter((category) => category !== "N/A")
          .map((category) => ({
            id: category,
            name: category,
          }));

        setCategories(extractedCategories);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  // Filtered articles for category selection
  const filteredArticles = selectedCategory
    ? articles.filter((article) => article.category === selectedCategory)
    : articles;

  // Dynamically calculate the total number of pages for the filtered articles
  const totalPages = Math.ceil(filteredArticles.length / itemsPerPage);

  // Slice articles for the current page
  const paginatedArticles = filteredArticles.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <>
    <MetadescriptionSetterForPage metadescription={"Media"} />
    <TitleSetterForPage title={"Media"} />
    <Grid
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />
      <Grid item xs={12} md={10} lg={10} mt={isMobile ? 10 : 20}>
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            fontWeight: "bold",
            textAlign: "center",
            color: "blue",
          }}
        >
          Blogs
        </Typography>
      </Grid>

      {/* Category Buttons */}
      <Grid item xs={12} md={10} lg={10} sx={{ mt: 4 }}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              variant={selectedCategory === null ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleCategorySelect("all")}
            >
              All
            </Button>
          </Grid>
          {categories.map((category) => (
            <Grid item key={category.id}>
              <Button
                variant={selectedCategory === category.id ? "contained" : "outlined"}
                color="primary"
                onClick={() => handleCategorySelect(category.id)}
              >
                {category.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Blog Articles */}
      <Grid container xs={12} md={10} lg={10}>
        <ArticleGrid articles={paginatedArticles} />
      </Grid>

      {/* Current Page Count */}
      {/* {paginatedArticles.length > 0 && (
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Showing {paginatedArticles.length} blogs on this page.
        </Typography>
      )} */}

      {/* Pagination */}

      
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
        
          
          page={page}
          onChange={handleChange}
          variant="text"
          shape="circular"
          color="primary"
          siblingCount={1}
          boundaryCount={1}
          showFirstButton
          showLastButton
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: { xs: "0.75rem", sm: "1rem" },
            },
            mt: 2,
          }}
        />
      )}

      <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
        <Footer />
      </Grid>
    </Grid>
    </>
  );
};

export default Media;
