import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Grid } from "@mui/material";
import axios from "axios";

const Texteditor = () => {
  const [bodyContent, setBodyContent] = useState("");

  const handleEditorChange = (content) => {
    setBodyContent(content);
  };
  const handleEditorImageUpload = async (blobInfo, progress) => {
    const formData = new FormData();
    // console.log("====================================");
    // console.log(blobInfo.blob());
    // console.log("====================================");
    formData.append("file", blobInfo.blob()); // Sending the image as 'file'

    try {
      // console.log("====================================");
      // console.log(
      //   "process.env.REACT_APP_BASE_URL",
      //   process.env.REACT_APP_BASE_URL
      // );
      // console.log("====================================");

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/articleimages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            progress(percentCompleted);
          },
        }
      );
      // console.log("repsone after the image upload,", response.data);
      return response.data.file; // The URL returned by the backend
    } catch (error) {
      console.error("Error uploading image to TinyMCE:", error);
      throw error;
    }
  };

  return (
    <Grid
      container
      xs={12}
      md={12}
      lg={12}
      mt={2}
      justifyContent={"center"}
      style={{
        maxWidth: "auto",
        padding: "0 16px",
        boxSizing: "border-box",
      }}
    >
      <Grid
        item
        xs={12}
        style={{ border: "1px solid blue", borderRadius: "8px" }}
      >
        <Editor
          apiKey="0db2zg1hl6crmc1q0rxtjcqqe5pgkjex5r5yx7q8v07e7nj2"
          value={bodyContent}
          init={{
            height: 500,
            plugins:
              "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount",
            toolbar:
              "undo redo | styles formatselect | bold italic backcolor | code | link image | alignleft aligncenter alignright alignjustify | numlist bullist | print",
            images_upload_handler: handleEditorImageUpload,
            content_style: `
              body {
                font-family: Arial, sans-serif;
                font-size: 14px;
              }
            `,
          }}
          onEditorChange={(content) => handleEditorChange(content)}
        />
      </Grid>
    </Grid>
  );
};

export default Texteditor;
