import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";

export const SubscriberTable = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [response, setResponse] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedSubscriberId, setSelectedSubscriberId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/getallsubscriber`
        );
        setSubscribers(response.data.subscribers.reverse());
        setLoading(false);
      } catch (error) {
        console.error("Error fetching subscribers:", error);
        setLoading(false);
      }
    };

    fetchSubscribers();
  }, []);

  const handleDelete = async () => {
    if (selectedSubscriberId) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_URL}/api/deleteSubscriber/${selectedSubscriberId}`
        );
        setResponse("Subscriber deleted successfully!");
        setSubscribers(
          subscribers.filter(
            (subscriber) => subscriber._id !== selectedSubscriberId
          )
        );
        setSelectedSubscriberId(null);
      } catch (error) {
        console.error("Error deleting subscriber:", error);
        setResponse("Error deleting subscriber!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setConfirmDialogOpen(false);
        setTimeout(() => {
          setResponse("");
        }, 4000);
      }
    }
  };

  const handleConfirmDelete = (id) => {
    setSelectedSubscriberId(id);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setSelectedSubscriberId(null);
  };

  return (
    <Box p={4} display="flex" justifyContent="center">
      <DrawerHeader />

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 10,
          alignItems: "center",
        }}
      >
        <Grid
          container
          xs={12}
          md={10}
          lg={12}
          direction="column"
          sx={{
            background: darkMode
            ? "linear-gradient(to left, #93E9BE, #BBDEFB, #F5F5DC, #64B5F6)"
            : "linear-gradient(to right, #93E9BE, #B0C4DE, #BBDEFB, #BBDEFB)",
            // borderRadius: 2,
            boxShadow: 8,
            p: 3,
            // border: "2px solid black",
            position: "relative",
            overflow: "hidden", // Ensure content stays within the borders
          }}
        >
          {response && (
            <Alert
              severity="success"
              color="warning"
              sx={{
                mb: 2,
                width: "300px",
                mx: "auto",
                position: "absolute",
                top: 20,
                left: 40,
                right: 0,
                transition: "opacity 0.5s ease-in-out",
              }}
            >
              <Typography variant="body1" color="secondary" mt={2}>
                {response}
              </Typography>
            </Alert>
          )}
          <Typography
            variant="h5"
            sx={{  color: "Midnight blue", textAlign: "center" }}
          >
            Subscriber Information
          </Typography>

          <Grid
            sx={{
              width: "98%",
              backgroundColor: "white",
              // borderRadius: 2,
              mt: 5,
              // p: 2,
              overflowX: "auto", // Prevent horizontal overflow
              // border: "1px solid #ddd",
            }}
          >
            {loading ? ( // Show loader if loading is true
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <SyncLoader color="#4A90E2" />
              </Box>
            ) : (
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  // border: "1px solid #ddd",
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2), 0px 4px 6px rgba(0, 0, 0, 0.1)",

                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        // border: "1px solid #ddd",
                                                  backgroundColor: "#191970",

                        color: "white",
                      }}
                    >
                      ID
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        // border: "1px solid #ddd",
                                                  backgroundColor: "#191970",

                        color: "white",
                      }}
                    >
                      NAME
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        // border: "1px solid #ddd",
                                                  backgroundColor: "#191970",

                        color: "white",
                      }}
                    >
                      EMAIL
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        // border: "1px solid #ddd",
                                                  backgroundColor: "#191970",

                        color: "white",
                      }}
                    >
                      ACTIONS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {subscribers.map((subscriber, index) => (
                    <tr key={subscriber._id}>
                      <td style={{ border: "1px solid #ddd", padding: "6px" }}>
                        {index + 1}
                      </td>
                       <td style={{ border: "1px solid #ddd", padding: "6px" }}>
                        {subscriber.name}
                      </td>
                       <td style={{ border: "1px solid #ddd", padding: "6px" }}>
                        {subscriber.email}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "6px" }}>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleConfirmDelete(subscriber._id)}
                          sx={{
                            ml: 1,
                            backgroundColor: "red",
                            "&:hover": { backgroundColor: "darkred" },
                            color: "white",
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this subscriber?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubscriberTable;
