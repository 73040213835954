import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  LinearProgress,
  Snackbar,
  SnackbarContent,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { Cancel as CancelIcon } from "@mui/icons-material";
import {
  handleEditorImageDelete,
  handleEditorImageUpload,
  handleEditorImageUploads,
} from "../../utils/imageUpload";
import { styled } from "@mui/system";
import BarLoader from "react-spinners/BarLoader";



const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: "100%",
  height: "100%",
  margin: 0,
  borderRadius: "15px",
  backgroundColor: "#f0f4f8",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#4CAF50",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#1976D2",
    },
    "&:hover fieldset": {
      borderColor: "#4CAF50",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4CAF50",
    },
  },
  backgroundColor: "white",
  borderRadius: "8px",
  marginBottom: "16px",
}));

const StyledButton = styled(Button)(({ color }) => ({
  padding: "12px 24px",
  borderRadius: "8px",
  fontWeight: "bold",
  fontSize: "0.95rem",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

export const EditArticle = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const initialValues = {
    headertext: "",
    metadescription: "",
    shortdescription: "",
    imagelink: "",
    category: "",
    author: "",
    body: "",
    date: "",
  };

  const [articleData, setArticleData] = useState(initialValues);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchArticleData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/cards/getonearticles/${slug}`
        );
        //   setArticleData(response.data);
        // } catch (error) {

        const data = response.data;
        setArticleData({
          ...data,
          date: data.date
            ? new Date(data.date).toISOString().split("T")[0]
            : "", // Format the date to YYYY-MM-DD
        });
      } catch (error) {
        console.error("Error fetching article data:", error);
        setSnackbarMessage("Failed to fetch article data.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    const fetchAuthors = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/link`
        );
        setAuthors(response.data.authors || []);
      } catch (error) {
        console.error("Error fetching authors:", error);
        setSnackbarMessage("Failed to fetch authors.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/getcategories`
        );
        setCategories(response.data.categories || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setSnackbarMessage("Failed to fetch categories.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    };

    fetchArticleData();
    fetchAuthors();
    fetchCategories();
  }, [slug]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticleData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditorChange = (content) => {
    setArticleData((prevData) => ({ ...prevData, body: content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let updatedArticleData = { ...articleData };

      // Handle image file upload
      if (imageFile) {
        const formData = new FormData();
        formData.append("imagelink", imageFile); // Ensure the file key is 'imagelink'
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_URL}/api/cards/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Ensure the correct headers are set
            },
          }
        );
        updatedArticleData.imagelink = uploadResponse.data.imageUrl; // Correctly set the image link from the response
      }

      const apiUrl = `${process.env.REACT_APP_URL}/api/cards/articles/${slug}`;
      const response = await axios.put(apiUrl, updatedArticleData);

      if (response.status === 200) {
        setSnackbarMessage("Article updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Navigate after showing snackbar message
        setTimeout(() => {
          navigate("/articlestable");
        }, 1500);
      }
    } catch (error) {
      console.error("Error updating article:", error);
      setSnackbarMessage("Failed to update the article. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleImageLinkCancel = () => {
    setArticleData((prevData) => ({ ...prevData, imagelink: "" }));
    setImageFile(null);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 3 * 1024 * 1024) {
        // Check if the file size is more than 3MB
        setSnackbarMessage("Uploaded image is more than 3MB");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        return;
      }
      setImageFile(selectedFile);
      const file = URL.createObjectURL(selectedFile);
      setArticleData((prevData) => ({ ...prevData, imagelink: file }));
    }
  };

  const initEditorConfig = {
    height: 500,
    plugins:
      "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount",
    toolbar:
      "undo redo | formatselect | bold italic | link image removeimage | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
    image_upload_handler: (blobInfo, success) => {
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      axios
        .post(`${process.env.REACT_APP_URL}/api/cards/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          success(response.data.imageUrl); // The backend should return the image URL
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          // failure("Image upload failed");
        });
    },
    setup: (editor) => {
      editor.ui.registry.addButton("removeimage", {
        text: "Remove Image",
        onAction: () => {
          const selectedNode = editor.selection.getNode();
          if (selectedNode.nodeName === "IMG") {
            editor.execCommand("mceRemoveNode", false, selectedNode);
          }
        },
      });
    },
  };

  if (loading) return <CircularProgress />;


  return (

    
    <StyledCard variant="outlined">
      <CardContent style={{ padding: "30px" }}>
        <Container>
          <Typography variant="h4" gutterBottom>
            Edit Article
          </Typography>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "Right" }} // Position at the bottom center
          >
            <SnackbarContent
              message={snackbarMessage}
              style={{
                backgroundColor:
                  snackbarSeverity === "success"
                    ? "green"
                    : snackbarSeverity === "error"
                    ? "red"
                    : "orange",
              }}
            />
          </Snackbar>


          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Article Header"
                  name="headertext"
                  value={articleData.headertext}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Category"
                  name="category"
                  value={articleData.category}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Author"
                  name="author"
                  value={articleData.author}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {/* <label htmlFor="date"> Date:</label> */}
                <StyledTextField
                  type="date"
                  id="date"
                  name="date"
                  value={articleData.date}
                  onChange={handleChange}
                  style={{
                    // padding: "6px",
                    // marginTop: "10px",
                    borderRadius: "5px",
                    // border: "1px solid #ccc",
                    width: "100%",
                  }}
                />
              </Grid>


              {/* <Grid item xs={12} md={6}> */}
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label="Meta Description"
                  name="metadescription"
                  value={articleData.metadescription}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label="Short Description"
                  name="shortdescription"
                  value={articleData.shortdescription}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label="Image Link"
                  name="imagelink"
                  value={articleData.imagelink}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <Button
                        variant="contained"
                        component="label"
                        style={{ marginLeft: 10 }}
                      >
                        Upload
                        <input type="file" hidden onChange={handleFileChange} />
                      </Button>
                    ),
                  }}
                />
                {articleData.imagelink && (
                  <div style={{ marginTop: "10px", position: "relative" }}>
                    <img
                      src={articleData.imagelink}
                      alt="Article Preview"
                      style={{
                        maxWidth: "600px", // Set the max width of the image
                        maxHeight: "600px", // Set the max height of the image
                        height: "auto", // Maintain aspect ratio
                        width: "auto", // Maintain aspect ratio
                        borderRadius: "8px", // Optional: Add rounded corners for a polished look
                      }}
                    />
                    <IconButton
                      onClick={handleImageLinkCancel}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "#fff",
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                <Editor
                  apiKey="7obrevvpz409sm0uk6qluafdw0gxyl34mdy1qft094fhg1pe"
                  value={articleData.body}
                  init={{
                    height: 500,
                    selector: "textarea",
                    plugins:
                      "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount accordion",
                    toolbar:
                      "undo redo | styles | formatselect | bold italic backcolor | code | link | image | alignleft aligncenter alignright alignjustify | numlist bullist | print",
                    menu: {
                      favs: {
                        title: "My Favorites",
                        items: "code visualaid | searchreplace | emoticons",
                      },
                    },
                    menubar:
                      "favs file edit view insert lists format tools table help",
                    image_uploadtab: true,
                    image_advtab: true,
                    setup: (editor) => {
                      editor.on("init", () => {
                        const removeDeleteButton = (image) => {
                          if (image && image.parentElement) {
                            const button =
                              image.parentElement.querySelector(
                                ".delete-image-btn"
                              );
                            if (button) {
                              button.remove();
                            }
                          }
                        };
                        editor.getDoc().addEventListener("click", (event) => {
                          if (event.target && event.target.tagName === "IMG") {
                            const image = event.target;
                            removeDeleteButton(image);
                            const existingButton =
                              image.parentElement?.querySelector(
                                ".delete-image-btn"
                              );
                            if (!existingButton) {
                              const button = document.createElement("button");
                              button.className = "delete-image-btn";
                              button.innerText = "Delete";
                              button.style.position = "absolute";
                              button.style.top = "0";
                              button.style.left = "0";
                              button.style.backgroundColor = "#D32F2F";
                              button.style.color = "white";
                              button.style.border = "none";
                              button.style.borderRadius = "8px";
                              button.style.padding = "8px 16px";
                              button.style.cursor = "pointer";
                              button.style.zIndex = "1000";
                              button.style.boxShadow =
                                "0px 4px 8px rgba(0, 0, 0, 0.2)";
                              button.addEventListener("mouseover", () => {
                                button.style.backgroundColor = "#B71C1C";
                              });
                              button.addEventListener("mouseout", () => {
                                button.style.backgroundColor = "#D32F2F";
                              });
                              image.parentElement.style.position = "relative";
                              image.parentElement.appendChild(button);
                              button.addEventListener("click", async () => {
                                editor.windowManager.confirm(
                                  "Are you sure you want to delete this image?",
                                  async (result) => {
                                    if (result) {
                                      try {
                                        editor.dom.remove(image);
                                        await handleEditorImageDelete(
                                          image.src
                                        );
                                        removeDeleteButton(image);
                                      } catch (error) {
                                        console.error(
                                          "Error deleting image:",
                                          error
                                        );
                                      }
                                    }
                                  }
                                );
                              });
                            }
                          } else if (
                            event.target &&
                            event.target.tagName !== "IMG"
                          ) {
                            removeDeleteButton(event.target);
                          }
                        });
                      });
                    },
                    images_upload_handler: handleEditorImageUpload,
                  }}
                  onEditorChange={handleEditorChange}
                />

                {/* <Editor
                  apiKey="7obrevvpz409sm0uk6qluafdw0gxyl34mdy1qft094fhg1pe"
                  value={articleData.body}
                  init={{
                    height: 500,
                    selector: "textarea",
                    plugins:
                      "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount accordion",
                    toolbar:
                      "undo redo | styles | formatselect | bold italic backcolor | code | link | image | alignleft aligncenter alignright alignjustify | numlist bullist | print",
                    menu: {
                      favs: {
                        title: "My Favorites",
                        items: "code visualaid | searchreplace | emoticons",
                      },
                    },
                    menubar:
                      "favs file edit view insert lists format tools table help",
                    image_uploadtab: true,
                    image_advtab: true,
                    setup: (editor) => {
                      editor.on("init", () => {
                        const removeDeleteButton = (image) => {
                          if (image && image.parentElement) {
                            const button =
                              image.parentElement.querySelector(
                                ".delete-image-btn"
                              );
                            if (button) {
                              button.remove();
                            }
                          }
                        };
                        editor.getDoc().addEventListener("click", (event) => {
                          // Check if the clicked element is an image
                          if (event.target && event.target.tagName === "IMG") {
                            const image = event.target;
                            // Remove existing delete button before adding a new one
                            removeDeleteButton(image);
                            const existingButton =
                              image.parentElement?.querySelector(
                                ".delete-image-btn"
                              );
                            if (!existingButton) {
                              const button = document.createElement("button");
                              button.className = "delete-image-btn";
                              button.innerText = "Delete";
                              button.style.position = "absolute";
                              button.style.top = "0";
                              button.style.left = "0";
                              button.style.backgroundColor = "#D32F2F";
                              button.style.color = "white";
                              button.style.border = "none";
                              button.style.borderRadius = "8px";
                              button.style.padding = "8px 16px";
                              button.style.cursor = "pointer";
                              button.style.zIndex = "1000";
                              button.style.boxShadow =
                                "0px 4px 8px rgba(0, 0, 0, 0.2)";
                              // Add hover effect
                              button.addEventListener("mouseover", () => {
                                button.style.backgroundColor = "#B71C1C";
                              });
                              button.addEventListener("mouseout", () => {
                                button.style.backgroundColor = "#D32F2F";
                              });
                              // Ensure the parent element has relative positioning
                              image.parentElement.style.position = "relative";
                              image.parentElement.appendChild(button);
                              button.addEventListener("click", async () => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this image?"
                                  )
                                ) {
                                  try {
                                    // Remove the image
                                    editor.dom.remove(image);
                                    // Call your delete handler
                                    await handleEditorImageDelete(image.src);
                                    // Remove the button after the image is deleted
                                    removeDeleteButton(image);
                                  } catch (error) {
                                    console.error(
                                      "Error deleting image:",
                                      error
                                    );
                                  }
                                }
                              });
                            }
                          } else if (
                            event.target &&
                            event.target.tagName !== "IMG"
                          ) {
                            // If it's not an image, attempt to remove the delete button
                            removeDeleteButton(event.target);
                          }
                        });
                      });
                    },
                    images_upload_handler: handleEditorImageUpload,
                  }}
                  onEditorChange={handleEditorChange}
                /> */}
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Update Article"}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/articlestable")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </CardContent>
    </StyledCard>
  );
};

export default EditArticle;
