import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SyncLoader from "react-spinners/SyncLoader";

const AddCategories = () => {
  const [categoriesName, setCategoriesName] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [editingCategory, setEditingCategory] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  // Fetch categories on component mount
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/getcategories`
      );
      setCategories(response.data.reverse());
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Open the dialog for adding a category
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close the dialog for adding a category and reset input
  const handleClose = () => {
    setOpen(false);
    setCategoriesName("");
  };

  // Open the edit dialog
  const handleEditClickOpen = (category) => {
    setEditingCategory(category);
    setCategoriesName(category.categoriesName);
    setEditOpen(true);
  };

  // Close the edit dialog and reset input
  const handleEditClose = () => {
    setEditOpen(false);
    setCategoriesName("");
    setEditingCategory(null);
  };

  // Open the delete confirmation dialog
  const handleDeleteClickOpen = (category) => {
    setCategoryToDelete(category);
    setConfirmDeleteOpen(true);
  };

  // Close the delete confirmation dialog
  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setCategoryToDelete(null);
  };

  // Submit a new category with optimistic update
  const handleSubmit = async () => {
    const newCategory = { categoriesName };

    setCategories((prevCategories) => [newCategory, ...prevCategories]);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/addcategories`,
        { categoriesName }
      );

      setCategories((prevCategories) => {
        const updatedCategories = [...prevCategories];
        updatedCategories[0] = response.data.category;
        return updatedCategories;
      });

      setResponseMessage("Category added successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error creating category:", error);
      setResponseMessage("Error: Unable to create category");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);

      setCategories((prevCategories) =>
        prevCategories.filter((category) => category !== newCategory)
      );
    } finally {
      handleClose();
    }
  };

  const handleUpdate = async () => {
    if (!editingCategory) return;

    const updatedCategory = { categoriesName };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/api/updatecategories/${editingCategory._id}`,
        updatedCategory
      );

      setCategories((prevCategories) =>
        prevCategories.map((category) =>
          category._id === response.data.category._id
            ? response.data.category
            : category
        )
      );

      setResponseMessage("Category updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating category:", error);
      setResponseMessage("Error: Unable to update category");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      handleEditClose();
    }
  };

  // Handle category deletion
  const handleDelete = async () => {
    if (!categoryToDelete) return;

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/api/deleteCategories/${categoryToDelete._id}`
      );

      if (response.status === 200) {
        setCategories(
          categories.filter((category) => category._id !== categoryToDelete._id)
        );
        setResponseMessage("Category deleted successfully!");
        setSnackbarSeverity("success");
      } else {
        setResponseMessage("Error deleting category!");
        setSnackbarSeverity("error");
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting category:", error);
      setResponseMessage("Error deleting category!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      handleConfirmDeleteClose();
    }
  };

  // Close the snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={6} display="flex" justifyContent={"center"} direction="row" mt={12}>
      <DrawerHeader />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: "100%",
            backgroundColor:
              snackbarSeverity === "success" ? "#90EE90" : "#f44336",
            color: "white",
          }}
        >
          {responseMessage}
        </Alert>
      </Snackbar>

      <Grid
        container
        xs={12}
        md={10}
        lg={10}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Grid
            sx={{
              p: 3,
              background: darkMode
                ? "linear-gradient(to left, #93E9BE, #BBDEFB, #F5F5DC, #64B5F6)"
                : "linear-gradient(to right, #93E9BE, #B0C4DE, #BBDEFB, #BBDEFB)",
              // borderRadius: 2,
              position: "relative",
              boxShadow: 8,
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{ mb: 2, color: "Midnight blue" }}
            >
              Categories Information
            </Typography>
            <Button
              variant="contained"
              // color="primary"
              onClick={handleClickOpen}
              sx={{
                position: "absolute",
                top: { xs: 8, sm: 16 },
                right: { xs: 8, sm: 16 },
                padding: { xs: "4px 8px", sm: "6px 16px" },
                fontSize: { xs: "12px", sm: "14px" },
                borderRadius: "8px",
                textTransform: "none",
                minWidth: { xs: "80px", sm: "100px" },
                // border: "2px solid black",
              }}
            >
              Add Categories
            </Button>

            <Grid
              sx={{
                // mt: 3,
                width: "100%",
                height: "auto",

                backgroundColor: "white",

                // borderRadius: 2,
                // p: 0.2,
                overflowX: "auto",
              }}
            >
              {loading ? ( // Show loader if loading is true
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <SyncLoader color="#4A90E2" />
                </Box>
              ) : (
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    boxShadow:
                      "0px 8px 16px rgba(0, 0, 0, 0.2), 0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#f4f4f4" }}>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",

                          color: "white",
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",

                          color: "white",
                        }}
                      >
                        Categories Name
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",
                        }}
                      >
                        Edit
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",

                          color: "white",
                        }}
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((category, index) => (
                      <tr
                        key={category._id || index}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <td
                          style={{
                            padding: "8px",
                            border: "1px solid #ddd",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            border: "1px solid #ddd",
                          }}
                        >
                          {category.categoriesName}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              mr: 1,
                              backgroundColor: "blue",
                              "&:hover": { backgroundColor: "darkblue" },
                              color: "white",
                            }}
                            onClick={() => handleEditClickOpen(category)}
                          >
                            Edit
                          </Button>
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              ml: 1,
                              backgroundColor: "red",
                              "&:hover": { backgroundColor: "darkred" },
                              color: "white",
                            }}
                            onClick={() => handleDeleteClickOpen(category)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>

      {/* Add Category Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            value={categoriesName}
            onChange={(e) => setCategoriesName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            sx={{
              ml: 1,
              backgroundColor: "green",
              "&:hover": { backgroundColor: "darkred" },
              color: "white",
            }}
          >
            Add
          </Button>

          <Button
            onClick={handleClose}
            sx={{
              ml: 1,
              backgroundColor: "red",
              "&:hover": { backgroundColor: "darkred" },
              color: "white",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Category Dialog */}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            value={categoriesName}
            onChange={(e) => setCategoriesName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleEditClose}
            sx={{
              ml: 1,
              backgroundColor: "red",
              "&:hover": { backgroundColor: "darkred" },
              color: "white",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            sx={{
              ml: 1,
              backgroundColor: "green",
              "&:hover": { backgroundColor: "darkred" },
              color: "white",
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog open={confirmDeleteOpen} onClose={handleConfirmDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this category?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddCategories;
