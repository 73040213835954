import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";

export const ArticlesTable = () => {
  const [articles, setArticles] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);

  // Fetch articles on component mount
  useEffect(() => {
    fetchArticles();
  }, []);

  // Function to fetch articles
  const fetchArticles = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        setArticles(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
        setLoading(false);
      });
  };

  // Function to handle article deletion
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_URL}/api/cards/deleteArticle/${id}`
      );
      setResponseMessage("Article deleted successfully!");
      setArticles(articles.filter((article) => article._id !== id));
      setSnackbarOpen(true); // Show Snackbar on success
    } catch (error) {
      console.error("Error deleting article:", error);
      setResponseMessage("Error deleting article!");
      setSnackbarOpen(true); // Show Snackbar on error
    }
  };

  // Function to handle delete button click
  const handleDeleteClick = (id) => {
    setArticleToDelete(id);
    setOpenConfirmDialog(true);
  };

  // Function to handle confirm delete
  const handleConfirmDelete = () => {
    if (articleToDelete) {
      handleDelete(articleToDelete);
    }
    setOpenConfirmDialog(false);
    setArticleToDelete(null);
  };

  // Function to handle cancel delete
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setArticleToDelete(null);
  };

  // Function to handle edit navigation
  const handleEdit = (slug) => {
    navigate(`/editarticle/${slug}`);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={4} display="flex">
      <DrawerHeader />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Auto hide after 6 seconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position of Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {responseMessage}
        </Alert>
      </Snackbar>

      <Grid
        container
        xs={12}
        md={10}
        lg={12}
        sx={{ display: "flex", flexDirection: "column", mt: 10 }}
      >
        <Grid
          item
          direction="column"
          sx={{
            // background: darkMode
            //   ? "linear-gradient(to left, #0A1828, #1a237e, #178582, #BFA181)"
            //   : "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
            // borderRadius: 2,
            // boxShadow: 8,
            // p: 3,
            // // border: "2px solid black",
            // position: "relative",
            background: darkMode
              ? "linear-gradient(to left, #93E9BE, #BBDEFB, #F5F5DC, #64B5F6)"
              : "linear-gradient(to right, #93E9BE, #B0C4DE, #BBDEFB, #BBDEFB)",
            // borderRadius: 2,
            boxShadow: 8,
            p: 3,
            position: "relative",
          }}
        >
          <Typography
            variant="h5"
            sx={{ mb: 2, color: "Midnight blue", textAlign: "center" }}
          >
            Blog Information
          </Typography>

          <Button
            variant="contained"
            // color="primary"
            onClick={() => navigate("/addarticle")}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              padding: "6px 16px",
              fontSize: "14px",
              borderRadius: "8px",
              textTransform: "none",
              // border: "2px solid black",
            }}
          >
            Add Articles
          </Button>

          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            sx={{
              width: "100%",
              backgroundColor: "white",
              // borderRadius: 2,
              // mt: 3,
            }}
          >
             <Paper>
              
              {loading ? ( // Show loader if loading is true
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <SyncLoader color="#4A90E2" />
                </Box>
                ) : (
                <table
                  style={{
                    width: "100%",
                    tableLayout: "fixed",
                    borderCollapse: "collapse",
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2), 0px 4px 6px rgba(0, 0, 0, 0.1)",
                    // border:"2px solid black"

                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",
                        }}
                      >
                        Category
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "2px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",
                        }}
                      >
                        Author
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",
                          wordBreak: "break-word", // Forces word breaks for long words
                          whiteSpace: "nowrap", // Keeps text in one line unless overflow occurs
                          overflow: "hidden", // Hides overflowing text
                          textOverflow: "ellipsis", // Adds ellipses for overflowed text
                        }}
                      >
                        Header
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",
                          wordBreak: "break-word", // Forces word breaks for long words
                          whiteSpace: "nowrap", // Keeps text in one line unless overflow occurs
                          overflow: "hidden", // Hides overflowing text
                          textOverflow: "ellipsis", // Adds ellipses for overflowed text
                        }}
                      >
                        Meta Description
                      </th>
                      <th
                        style={{
                          wordBreak: "break-word", // Forces word breaks for long words
                          whiteSpace: "nowrap", // Keeps text in one line unless overflow occurs
                          overflow: "hidden", // Hides overflowing text
                          textOverflow: "ellipsis", // Adds ellipses for overflowed text
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",
                        }}
                      >
                        Short Description
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",
                        }}
                      >
                        Chosen File
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",

                        }}
                      >
                        Edit
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          // border: "1px solid #ddd",
                          backgroundColor: "#191970",
                          color: "white",
                        }}
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {articles.map((article, index) => (
                      <tr key={article._id}>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {article.category}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {article.author}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {article.headertext}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {article.metadescription}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {article.shortdescription}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          <img
                            src={article.imagelink}
                            alt="Article"
                            rel="preload"
                            style={{
                              width: "100px",
                              height: "auto",
                              objectFit: "contain",
                            }}
                          />
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              mr: 1,
                              backgroundColor: "blue",
                              "&:hover": { backgroundColor: "darkblue" },
                              color: "white",
                            }}
                            onClick={() => handleEdit(article.slug)}
                          >
                            Edit
                          </Button>
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              ml: 1,
                              backgroundColor: "red",
                              "&:hover": { backgroundColor: "darkred" },
                              color: "white",
                            }}
                            onClick={() => handleDeleteClick(article._id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* Confirmation Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this article?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ArticlesTable;
